import React from 'react';
import BaseTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { isMobile } from 'react-device-detect';

const Tooltip = ({
  body,
  children,
  disabled,
  className,
  mouseLeaveDelay = 0,
  keepDisplayUponHover = false,
  placement = 'top',
  trigger,
  mouseEnterDelay
}) => {
  const disableOnMobile = isMobile && !trigger;

  if (disabled || disableOnMobile) {
    return children;
  }

  return (
    <BaseTooltip
      mouseLeaveDelay={keepDisplayUponHover ? undefined : mouseLeaveDelay}
      placement={placement}
      destroyTooltipOnHide={{ keepParent: false }}
      overlay={Array.isArray(body) ? body.map((item, index) => <div key={index}>{item}</div>) : body}
      overlayClassName={className}
      trigger={trigger}
      mouseEnterDelay={mouseEnterDelay}>
      {children}
    </BaseTooltip>
  );
};

export default Tooltip;
