import React, { useContext } from 'react';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import Box from './Box';
import RedirectState from './RedirectState';

const Unauthorized = () => {
  const { t } = useContext(ConfigContext);
  return (
    <Box linkTo="/login" linkText={t('loginRedirect')}>
      {() => <RedirectState text={t('unauthorizedPage')} redirect="/login" />}
    </Box>
  );
};

export default Unauthorized;
