import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import GlobalStyle from '@ubeya/shared-web/style/global';
import themeMaker from '@ubeya/shared-web/style/themeMaker';
import App from 'app';

const history = createBrowserHistory();

const REACT_QUERY_CONFIG = { defaultOptions: { queries: { staleTime: Infinity, refetchOnWindowFocus: false } } };

const theme = themeMaker();

const queryClient = new QueryClient(REACT_QUERY_CONFIG);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router history={history}>
      <GlobalStyle />
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </RecoilRoot>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
