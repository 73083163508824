import React from 'react';
import styled, { css } from 'styled-components';
import { CHAT_ICONS } from '@ubeya/shared/constants';
import { default as CircleIcon } from '../CircleIcon';

const Button = styled(CircleIcon)`
  position: relative;

  ${({ $disableHover }) =>
    $disableHover &&
    css`
      &:hover {
        background: transparent;
      }
    `}
`;

const ChatMessages = styled.span`
  background: ${({ theme, $badgeColor }) => ($badgeColor ? $badgeColor : theme.colors.info2)};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.surface};
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  left: 20px;
  font-size: 10rem;
`;

const ChatIcon = ({ iconType }) => CHAT_ICONS[iconType].component;

export const ChatButton = ({
  onClick,
  iconType = CHAT_ICONS.WHITE_FILLED.type,
  badgeColor,
  disableHover = false,
  messages = 0
}) => {
  return (
    <Button $disableHover={disableHover} onClick={onClick}>
      <ChatIcon iconType={iconType} />
      {messages > 0 && <ChatMessages $badgeColor={badgeColor}>{messages}</ChatMessages>}
    </Button>
  );
};
