import { useCallback, useMemo } from 'react';
import { useQueryParams } from '@ubeya/shared-web/hooks/useQueryParams';

const WEB_URL = process.env.WEB_URL;
const CREW_URL = process.env.CREW_URL;
const CHAT_URL = process.env.CHAT_URL;

const useRedirectUrl = () => {
  const { redirectUrl: redirectUrlParams } = useQueryParams();

  const getDomainTLD = useCallback((url) => url?.match(/(\w+\.\w+)$/)?.[0], []);
  const getLocalDomainTLD = useCallback((url) => url && url?.match(/(\w+:\w+)$/)?.[0], []);

  const extractDomainTLD = useCallback(
    (url) => {
      const base = decodeURIComponent(url)
        ?.replace('https://', '')
        ?.replace('http://', '')
        ?.split('?')?.[0] //with query params
        ?.split('/')?.[0]; //with params

      return getDomainTLD(base) || getLocalDomainTLD(base);
    },
    [getDomainTLD, getLocalDomainTLD]
  );

  const redirectUrl = useMemo(() => {
    if (!redirectUrlParams) {
      return undefined;
    }
    const decodedRedirectUrlDomainTLD = extractDomainTLD(redirectUrlParams);

    if ([extractDomainTLD(WEB_URL), extractDomainTLD(CHAT_URL)].includes(decodedRedirectUrlDomainTLD)) {
      return redirectUrlParams;
    }

    return undefined;
  }, [extractDomainTLD, redirectUrlParams]);

  const getRedirectUrlOrDefault = (defaultUrl) => (redirectUrl ? decodeURIComponent(redirectUrl) : defaultUrl);

  return {
    webRedirectUrl: getRedirectUrlOrDefault(WEB_URL),
    crewRedirectUrl: getRedirectUrlOrDefault(CREW_URL)
  };
};

export default useRedirectUrl;
