import React, { useState, useCallback, useMemo, useContext } from 'react';
import { SORT_BY_STRING } from '@ubeya/shared/utils/sorting';
import styled from 'styled-components';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';
import { TextWithTooltip } from '../../Tooltip';
const ProjectLiveViewPositionsFilterText = styled(TextWithTooltip)`
  max-width: 200px;
`;
const ProjectLiveViewPositionsFilter = ({
  slug,
  defaultValue,
  handleClose,
  onApply,
  search,
  onChange,
  externalOptions,
  hideLayout = false
}) => {
  const { t } = useContext(ConfigContext);
  const positionsOptions = (externalOptions[slug] || [])
    .map(({ id, value }) => ({
      value: id,
      label: value
    }))
    .sort(SORT_BY_STRING('label'));

  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];

      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      search
        ? (positionsOptions || []).filter(
            ({ label }) =>
              t('positions').toLowerCase().includes(search.toLowerCase()) ||
              label.toLowerCase().includes(search.toLowerCase())
          )
        : positionsOptions,
    [positionsOptions, search, t]
  );

  if (!filteredOptions.length) {
    return null;
  }

  return (
    <FilterLayout
      name={t('positions')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={hideLayout}>
      {filteredOptions.map(({ value, label }) => (
        <DropDown.Item key={value}>
          <CheckBox name={`position-${value}`} value={values.includes(value)} onChange={() => handleChange(value)}>
            <ProjectLiveViewPositionsFilterText>{label}</ProjectLiveViewPositionsFilterText>
          </CheckBox>
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default ProjectLiveViewPositionsFilter;
