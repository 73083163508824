import React from 'react';
import styled from 'styled-components';
import { FlexCenter } from '@ubeya/shared-web/components';
import { media } from '@ubeya/shared-web/style';
import loginUbeya from '@ubeya/shared-web/assets/login-ubeya.jpg';

const AuthLayoutRoot = styled.div`
  height: 100vh;
  display: flex;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const SignUpContainer = styled.div`
  ${media.mobile`
  display: flex;
  flex-direction: column;
  flex:1;
  align-items: center;
  justify-content: center;
  `}
  display: none;
`;

const Content = styled.div`
  display: flex;
  flex: 1.4;
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 30px;
`;

const LottieWrapper = styled(FlexCenter)`
  height: 100vh;
  width: 100%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const AuthLayout = ({ children }) => {
  return (
    <AuthLayoutRoot>
      <SignUpContainer>
        <LottieWrapper style={{ backgroundImage: `url(${loginUbeya})` }} />
        {/* <DontHaveAccountText>{t('dontHaveAnAccount')}</DontHaveAccountText> */}
        <ButtonContainer>
          {/* <Button quaternary onClick={onDontHaveAccClick}>
            {t('getStarted').toUpperCase()}
          </Button> */}
        </ButtonContainer>
      </SignUpContainer>
      <Content>{children}</Content>
    </AuthLayoutRoot>
  );
};

export default AuthLayout;
