import React from 'react';
import styled from 'styled-components';

const CompanyInitials = styled.div.attrs(() => ({ className: 'client-avatar-company-initials' }))`
  background: ${({ theme }) => theme.colors.eventModal.avatarBackground};
  color: ${({ theme }) => theme.colors.eventModal.avatarText};
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 500;
`;

const Logo = styled.img.attrs(() => ({ className: 'client-avatar-logo' }))`
  max-width: 24px;
  max-height: 24px;
  border-radius: 100%;
`;

const ClientAvatar = ({ name, logo }) => {
  if (logo) {
    return <Logo src={logo} />;
  }

  if (name) {
    return <CompanyInitials>{name[0]}</CompanyInitials>;
  }

  return <></>;
};

export default ClientAvatar;
