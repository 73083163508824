import { useCallback, useEffect, useRef } from 'react';

const useInterval = (callback, delay, startImmediately = false) => {
  const savedCallback = useRef();
  const savedId = useRef();

  const stop = useCallback(() => {
    const id = savedId.current;
    if (id) {
      savedId.current = undefined;
      clearInterval(id);
    }
  }, []);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    stop();

    return start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay]);

  const start = useCallback(() => {
    const handler = () => savedCallback.current();

    stop();

    if (delay !== null) {
      savedId.current = setInterval(handler, delay);
      startImmediately && handler();
    }

    return stop;
  }, [delay, startImmediately, stop]);

  return { start, stop };
};

export default useInterval;
