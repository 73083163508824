import React from 'react';
import { Field } from 'react-final-form';
import Select from '../Select';

const SelectField = ({
  title,
  name,
  validate,
  type,
  options,
  isMulti = true,
  onChange,
  onBlur,
  showInitialErrorWithoutTouch = false,
  ...restProps
}) => {
  return (
    <Field
      name={name}
      validate={validate}
      type={type}
      render={({ input, meta }) => {
        return (
          <Select
            {...input}
            value={input.value}
            onChange={(value) => {
              if (onChange) {
                onChange(value, input);
              } else {
                input.onChange(value);
              }
            }}
            onBlur={onBlur}
            title={title}
            isMulti={isMulti}
            options={options}
            error={(meta.touched || showInitialErrorWithoutTouch) && meta.error}
            {...restProps}
          />
        );
      }}
    />
  );
};

export default SelectField;
