import React, { useContext } from 'react';
import styled from 'styled-components';
import { required } from '@ubeya/shared/utils/form';
import { Button as BaseButton, Loader, InputField as BaseInputField } from '@ubeya/shared-web/components';
import { media } from '@ubeya/shared-web/style';
import { ReactComponent as BaseGmailIcon } from '@ubeya/shared-web/assets/gmail.svg';
import { ReactComponent as BaseOutlookIcon } from '@ubeya/shared-web/assets/outlook.svg';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import backgroundUbeyaDisplay from '@ubeya/shared-web/assets/blured-ubeya-scheduling.jpg';
import { Trans } from 'react-i18next';
import useAuth from '../hooks/useAuth';
import BaseBox from './Box';

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Box = styled(BaseBox)`
  max-width: 500px;
  width: 500px;
  height: 500px;
  align-items: center;
  position: relative;

  .box-title {
    ${media.tablet`line-height: 0.78`}
    margin: 0 0 43px;
    font-size: 36px;
    line-height: 1.2;
    text-align: center;
  }

  .box-sub-title {
    ${media.tablet`
    font-size: 16px;width: 400px;`}

    margin: 43px 0 59px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray000};
    line-height: 1.7;
  }

  .box-line-text {
    position: absolute;
    bottom: 50px;

    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    font-size: 14px;
    text-transform: uppercase;
  }
`;

const InputField = styled(BaseInputField)`
  ${media.tablet`width: 360px`}
  width: 200px;
  margin-bottom: 20px;
`;

const BodyContainer = styled.div`
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Button = styled(BaseButton)`
  ${media.tablet`width: 360px;height: 50px;padding: 13px 81px;`}

  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  width: 200px;
  font-size: 14px;
`;

const SucceededText = styled.div`
  font-size: 16px;
  justify-self: center;
  text-align: center;
  line-height: 25px;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
`;

const OutlookIcon = styled(BaseOutlookIcon)`
  height: 60px;
  width: 60px;
`;

const GmailIcon = styled(BaseGmailIcon)`
  height: 50px;
  width: 50px;
  margin-right: 20px;
`;

const LinkesToEmail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubmitSucceededRenderer = () => (
  <>
    <SucceededText>
      <Trans>pleaseCheckYourEmailToCompleteYourPasswordReset</Trans>
    </SucceededText>
    <LinkesToEmail>
      <Link href="https://mail.google.com/mail/u/0/#inbox" target="_blank" rel="noreferrer">
        <GmailIcon />
      </Link>

      <Link href="https://outlook.live.com/mail/0/" target="_blank" rel="noreferrer">
        <OutlookIcon />
      </Link>
    </LinkesToEmail>
  </>
);

const ForgotPassword = () => {
  const { forgotPassword } = useAuth();
  const { t } = useContext(ConfigContext);

  return (
    <Wrapper style={{ backgroundImage: `url(${backgroundUbeyaDisplay})` }}>
      <Box
        onSubmit={forgotPassword}
        title={t('forgotYourPassword')}
        subTitle={t('clickToSetNewPassword')}
        submitSucceededTitle={t('passwordReset')}
        linkTo="/login"
        showSubTitleUponSubmitted={false}
        linkText={t('backToLogin')}>
        {({ submitting, valid, submitSucceeded }) => (
          <>
            {submitSucceeded ? (
              <SubmitSucceededRenderer />
            ) : (
              <BodyContainer>
                <InputField name="email" placeholder={t('enterYourEmail')} validate={required} title={t('email')} />

                <Button primary disabled={submitting || !valid}>
                  {submitting ? <Loader size="xsmall" /> : t('resetPassword')}
                </Button>
              </BodyContainer>
            )}
          </>
        )}
      </Box>
    </Wrapper>
  );
};

export default ForgotPassword;
