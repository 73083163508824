/* eslint-disable no-console */
import { useCallback, useMemo } from 'react';
import { useField } from 'react-final-form';
import { sortArray } from '@ubeya/shared/utils/sorting';

const useArrayManagement = (field, { isDisabled } = { isDisabled: false }) => {
  const {
    input: { value, onChange }
  } = useField(field);

  const handleDelete = useCallback(
    (item) => {
      if (isDisabled) {
        return;
      }

      const itemIndex = typeof item === 'number' ? value.findIndex(({ id }) => id === item) : value.indexOf(item);
      const isRealItem = value[itemIndex].id > 0;

      onChange(
        isRealItem
          ? value.map((valueItem, index) => (itemIndex !== index ? valueItem : { ...valueItem, status: 'deleted' }))
          : value.filter((_, index) => index !== itemIndex)
      );
    },
    [value, isDisabled, onChange]
  );

  const handleAdd = useCallback(
    (item) => {
      if (isDisabled) {
        return;
      }

      onChange([...value, { id: -Date.now(), ...item, status: 'added' }]);
    },
    [isDisabled, onChange, value]
  );

  const handleEdit = useCallback(
    (item, newValue) => {
      if (isDisabled) {
        return;
      }

      const itemIndex = typeof item === 'number' ? value.findIndex(({ id }) => id === item) : value.indexOf(item);

      onChange(
        value.map((valueItem, index) =>
          itemIndex !== index ? valueItem : { ...valueItem, ...newValue, status: valueItem.status || 'edited' }
        )
      );
    },
    [isDisabled, onChange, value]
  );

  const handleAddItems = useCallback(
    (items) => {
      if (isDisabled) {
        return;
      }

      onChange([...value, ...items.map((item) => ({ id: -Math.random(), ...item, status: 'added' }))]);
    },
    [isDisabled, onChange, value]
  );

  const data = useMemo(() => (value || []).filter(({ status }) => status !== 'deleted'), [value]);

  const handleSortItems = useCallback(
    ({ oldIndex, newIndex }) => {
      onChange(
        sortArray(data, oldIndex, newIndex).map((item, index) => ({
          ...item,
          status: item.status || 'edited',
          priority: index + 1
        }))
      );
    },
    [data, onChange]
  );

  return { data, handleAdd, handleDelete, handleEdit, handleAddItems, handleSortItems, onChange };
};

export default useArrayManagement;
