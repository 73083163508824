import moment from 'moment';
import { API_DATE_FORMAT } from '@ubeya/shared/constants';
import exportExcel from './exportExcel';

const DEPRECATED_TEXT = ', Invalid date';

const FIELD_SLUGS = {
  // Fields that their slug is different than the localization key
  PHONE_SECONDARY: 'phoneSecondary',
  EMPLOYMENT_TYPE_ID: 'employmentTypeId',
  DEPARTMENT_ID: 'departmentId',
  IS_PENDING: 'isPending',
  IS_DAILY: 'isDaily',
  IS_SHIFT: 'isShift'
};

// Fields that their slug is different than the localization key
const FIELD_NAMES_BY_SLUG = {
  [FIELD_SLUGS.PHONE_SECONDARY]: 'secondaryPhone',
  [FIELD_SLUGS.EMPLOYMENT_TYPE_ID]: 'employmentType',
  [FIELD_SLUGS.DEPARTMENT_ID]: 'department',
  [FIELD_SLUGS.IS_PENDING]: 'pendingApproval',
  [FIELD_SLUGS.IS_DAILY]: 'daily',
  [FIELD_SLUGS.IS_SHIFT]: 'shift'
};

const getProfileFieldName = (t, profileFieldSlug) => {
  if (profileFieldSlug?.includes('payrollFields')) {
    return profileFieldSlug
      .split('.')
      .map((slug) => t(slug))
      .join(' - ');
  }

  if (Object.keys(FIELD_NAMES_BY_SLUG).includes(profileFieldSlug)) return t(FIELD_NAMES_BY_SLUG[profileFieldSlug]);

  return t(profileFieldSlug);
};

const formatShiftDetails = ({ shift, shortDateMonthFormat, timeFormat }) => {
  const { shiftDate, shiftStartTime, shiftEndTime, shiftPosition, shiftLocation } = shift;

  // dana 13:00-14:00, area
  const details = [];

  if (shiftDate) details.push(moment(shiftDate, API_DATE_FORMAT).format(shortDateMonthFormat));
  if (shiftPosition) details.push(shiftPosition);
  if (shiftStartTime) details.push(moment.utc(shiftStartTime).format(timeFormat));
  if (shiftEndTime) details.push(`-${moment.utc(shiftEndTime).format(timeFormat)}`);
  if (shiftLocation) details.push(`, ${shiftLocation}`);

  return details.join('');
};

export const generateAuditLogBody = ({
  t,
  type,
  data,
  timeFormat = 'HH:mm',
  shortDateMonthFormat = 'DD/MM',
  getProfileFieldValue
}) => {
  const reporterTitle = data.data.reportedType === 'admin' ? t('Administrator') : t('User');

  const {
    reportedType,
    reporterName,
    startTime: startTimeRaw,
    endTime: endTimeRaw,
    employeeName,
    shiftName,
    shiftDate,
    shiftPosition,
    date,
    hourlyRate,
    adminName,
    jobDate,
    positionName,
    projectName,
    projectDate,
    captainName,
    positionSlug,
    value,
    message,
    slug,
    srcProjectDate,
    deactivateReason,
    isFile,
    isPhoto,
    fileChange,
    fieldType,
    fieldName,
    prevValue,
    newValue,
    prevShift,
    newShift
  } = data.data;

  const startTime = startTimeRaw && moment.utc(startTimeRaw).format(timeFormat);
  const endTime = endTimeRaw && moment.utc(endTimeRaw).format(timeFormat);

  let text;
  let values = {};

  switch (type) {
    case 'UpdatePayrollField': {
      const slugTrans = t(slug);

      text = t('updatePayrollFieldAuditLog', {
        adminName,
        slugTrans,
        value,
        employeeName: employeeName || reporterName,
        shiftName,
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      }).replace(DEPRECATED_TEXT, '');

      break;
    }

    case 'ResetTimesheetTime': {
      text = t(shiftDate ? 'timesheetResetTimeCreateAuditLog' : 'timesheetJobResetTimeCreateAuditLog', {
        adminName: reporterName,
        reporterTitle,
        employeeName: employeeName || reporterName,
        shiftName,
        date: moment(date, API_DATE_FORMAT).format(shortDateMonthFormat),
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      }).replace(DEPRECATED_TEXT, '');

      break;
    }

    case 'CreateTimesheetEndTime': {
      text = t(
        reportedType === 'admin'
          ? shiftDate
            ? 'timesheetEndTimeCreateAuditLog'
            : 'timesheetJobEndTimeCreateAuditLog'
          : 'timesheetUserEndTimeCreateAuditLog',
        {
          adminName: reporterName,
          reporterTitle,
          employeeName: employeeName || reporterName,
          shiftName,
          date: moment(date, API_DATE_FORMAT).format(shortDateMonthFormat),
          shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat),
          endTime
        }
      ).replace(DEPRECATED_TEXT, '');

      break;
    }
    case 'ClientCreateTimesheetEndTime': {
      text = t(shiftDate ? 'clientTimesheetEndTimeCreateAuditLog' : 'clientTimesheetJobEndTimeCreateAuditLog', {
        adminName: reporterName,
        reporterTitle,
        employeeName: employeeName || reporterName,
        shiftName,
        date: moment(date, API_DATE_FORMAT).format(shortDateMonthFormat),
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat),
        endTime
      }).replace(DEPRECATED_TEXT, '');

      break;
    }

    case 'CreateTimesheetStartTime': {
      text = t(
        reportedType === 'admin'
          ? shiftDate
            ? 'timesheetStartTimeCreateAuditLog'
            : 'timesheetJobStartTimeCreateAuditLog'
          : 'timesheetUserStartTimeCreateAuditLog',
        {
          adminName: reporterName,
          reporterTitle,
          employeeName: employeeName || reporterName,
          shiftName,
          date: moment(date, API_DATE_FORMAT).format(shortDateMonthFormat),
          shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat),
          startTime
        }
      ).replace(DEPRECATED_TEXT, '');

      break;
    }
    case 'ClientCreateTimesheetStartTime': {
      text = t(shiftDate ? 'clientTimesheetStartTimeCreateAuditLog' : 'clientTimesheetJobStartTimeCreateAuditLog', {
        adminName: reporterName,
        reporterTitle,
        employeeName: employeeName || reporterName,
        shiftName,
        date: moment(date, API_DATE_FORMAT).format(shortDateMonthFormat),
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat),
        startTime
      }).replace(DEPRECATED_TEXT, '');

      break;
    }
    case 'AutoFillTimesheet': {
      text = t('timesheetAutoFillTimeCreateAuditLog', {
        adminName: reporterName,
        reporterTitle,
        employeeName: employeeName || reporterName,
        shiftName,
        startTime,
        endTime,
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      }).replace(DEPRECATED_TEXT, '');

      break;
    }

    case 'UpdateJobPayroll': {
      values = {
        hourlyRate,
        employeeName,
        adminName,
        jobDate: moment(new Date(jobDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      };
      text = 'updateJobPayrollAuditLog';

      break;
    }
    case 'UpdateEmployeeSlotPayroll': {
      values = {
        hourlyRate,
        employeeName,
        shiftName,
        adminName,
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      };
      text = 'updateEmployeeSlotPayrollAuditLog';

      break;
    }

    case 'UpdateShiftPayroll': {
      values = {
        hourlyRate,
        employeeName,
        shiftName,
        adminName,
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      };

      text = 'updateShiftPayrollAuditLog';

      break;
    }

    case 'UpdateEmployeePayroll': {
      values = {
        hourlyRate,
        employeeName,
        shiftName,
        adminName,
        jobDate,
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      };
      text = 'updateEmployeePayrollAuditLog';

      break;
    }

    case 'UpdateEmployeePositionPayroll': {
      values = {
        hourlyRate,
        employeeName,
        shiftName,
        adminName,
        jobDate,
        positionName: t(positionName),
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      };
      text = 'updateEmployeePositionPayrollAuditLog';
      break;
    }

    case 'EmployeeBroadcastForShift': {
      values = {
        shiftName,
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat),
        shiftPosition: shiftPosition ? ` - ${shiftPosition}` : '',
        employeeName
      };
      text = 'employeeBroadcastForShiftAuditLog';

      break;
    }

    case 'ApprovedWorkingHours': {
      values = {
        adminName,
        shiftName,
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat),
        employeeName,
        startTime,
        endTime,
        reporterName
      };
      text = 'approvedWorkingHoursAuditLog';

      break;
    }

    case 'EmployeeBookedForShift': {
      values = {
        adminName,
        shiftName,
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat),
        shiftPosition: shiftPosition ? ` - ${shiftPosition}` : '',
        employeeName
      };
      text = 'employeeBookedForShiftAuditLog';

      break;
    }

    case 'EmployeeUnBookedForShift': {
      values = {
        adminName,
        shiftName,
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat),
        shiftPosition: shiftPosition ? ` - ${shiftPosition}` : '',
        employeeName,
        message: message ? `. ${message}` : ''
      };

      text = 'employeeUnBookedForShiftAuditLog';
      break;
    }

    case 'EmployeeRequestReleaseFromShift': {
      values = {
        shiftName,
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat),
        employeeName
      };

      text = 'EmployeeRequestReleaseFromShiftAuditLog';
      break;
    }

    case 'EmployeeAutoBookedForShift': {
      values = {
        adminName,
        shiftName,
        shiftDate: moment(new Date(shiftDate), API_DATE_FORMAT).format(shortDateMonthFormat),
        employeeName
      };
      text = 'employeeAutoBookedForShiftAuditLog';

      break;
    }

    case 'EmployeeLeftCompany': {
      values = { employeeName };
      text = 'employeeLeftCompanyAuditLog';
      break;
    }

    case 'EmployeeCreatedByAPI': {
      values = { employeeName };
      text = 'employeeCreatedByAPIAuditLog';
      break;
    }

    case 'EmployeeSMSInvited': {
      values = { employeeName, adminName };
      text = 'employeeSMSInvitedAuditLog';
      break;
    }

    case 'EmployeeChangeShiftAutoLogUseCase': {
      if (prevShift && newShift) {
        values = {
          adminName,
          prevShift: formatShiftDetails({
            shift: prevShift,
            shortDateMonthFormat,
            timeFormat
          }),
          newShift: formatShiftDetails({ shift: newShift, shortDateMonthFormat, timeFormat }),
          employeeName
        };
        text = 'employeeChangedShiftAuditLogNew';
      } else {
        values = {
          adminName,
          shiftDate,
          employeeName
        };
        text = 'employeeChangedShiftAuditLogOld';
      }

      break;
    }

    case 'AdminUploadedEmployees': {
      values = { adminName };
      text = 'adminUploadedEmployeesAuditLog';
      break;
    }

    case 'EmployeeDeleted': {
      values = {
        adminName,
        employeeName
      };
      text = 'employeeDeleteAuditLog';
      break;
    }

    case 'EmployeeRestored': {
      values = {
        adminName,
        employeeName
      };
      text = 'employeeRestoredAuditLog';
      break;
    }

    case 'EmployeeCreated': {
      values = {
        adminName,
        employeeName
      };
      text = 'employeeCreateAuditLog';
      break;
    }
    case 'EmployeeUpdated': {
      values = {
        adminName,
        employeeName,
        fieldName: getProfileFieldName(t, fieldName),
        fileChange,
        document: isPhoto ? 'photo' : 'file'
      };

      const isDocument = isFile || isPhoto;

      if (!isDocument) {
        values.prevValue = getProfileFieldValue?.({
          profileFieldName: fieldName,
          profileFieldType: fieldType,
          profileFieldValue: prevValue
        });

        values.newValue = getProfileFieldValue?.({
          profileFieldName: fieldName,
          profileFieldType: fieldType,
          profileFieldValue: newValue
        });
      }

      text = isDocument
        ? 'employeeUploadDocumentAuditLog'
        : fieldName
        ? 'employeeUpdateFieldAuditLog'
        : 'employeeUpdateAuditLog';
      break;
    }

    case 'EmployeeActivated': {
      values = {
        adminName,
        employeeName
      };
      text = 'employeeActivatedAuditLog';
      break;
    }
    case 'EmployeeDeactivated': {
      values = {
        adminName,
        employeeName,
        deactivateReason
      };
      text = deactivateReason ? 'employeeDeactivatedWithReasonAuditLog' : 'employeeDeactivatedAuditLog';
      break;
    }

    case 'ProjectCreated': {
      values = {
        adminName,
        projectName,
        projectDate: moment(new Date(projectDate), API_DATE_FORMAT).format(shortDateMonthFormat),
        srcProjectDate: srcProjectDate
          ? moment(new Date(srcProjectDate), API_DATE_FORMAT).format(shortDateMonthFormat)
          : undefined
      };
      text = srcProjectDate ? 'projectCreateViaCopyAuditLog' : 'projectCreateAuditLog';
      break;
    }

    case 'ProjectDeleted': {
      const { projectLocation, projectEndDate } = data.data;
      const dateFormatted = moment(new Date(projectDate), API_DATE_FORMAT).format(shortDateMonthFormat);
      const endDateFormatted = moment(new Date(projectEndDate), API_DATE_FORMAT).format(shortDateMonthFormat);
      values = {
        adminName,
        projectName,
        projectLocation,
        projectDate: dateFormatted !== endDateFormatted ? `${dateFormatted} - ${endDateFormatted}` : dateFormatted
      };
      text = 'projectDeletedAuditLog';
      break;
    }

    case 'ProjectCaptainTimesheetStartTimeUpdated': {
      values = {
        captainName,
        employeeName,
        projectName,
        startTime,
        projectDate: moment(new Date(projectDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      };
      text = 'projectCaptainTimesheetStartTimeUpdated';
      break;
    }
    case 'ProjectInvoiceIssue': {
      values = {
        adminName,
        projectName,
        projectDate: moment(new Date(projectDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      };
      text = 'projectInvoiceIssue';
      break;
    }

    case 'ProjectCaptainTimesheetEndTimeUpdated': {
      values = {
        captainName,
        endTime,
        employeeName,
        projectName,
        projectDate: moment(new Date(projectDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      };
      text = 'projectCaptainTimesheetEndTimeUpdated';

      break;
    }
    case 'ProjectArchived': {
      values = {
        adminName,
        projectName,
        projectDate: moment(new Date(projectDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      };
      text = 'projectArchived';

      break;
    }

    case 'ProjectUnArchived': {
      values = {
        adminName,
        projectName,
        projectDate: moment(new Date(projectDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      };
      text = 'projectUnArchived';

      break;
    }

    case 'ProjectUpdated': {
      values = {
        adminName,
        projectName,
        projectDate: moment(new Date(projectDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      };
      text = 'projectUpdateAuditLog';

      break;
    }

    case 'ShiftUpdated': {
      values = {
        adminName,
        shiftName: positionSlug ? t(positionSlug) : '',
        ...(startTime && { startTime }),
        ...(endTime && { endTime })
      };
      text =
        startTime && endTime
          ? 'shiftUpdateAuditLogTime'
          : startTime
          ? 'shiftUpdateAuditLogStartTime'
          : endTime
          ? 'shiftUpdateAuditLogEndTime'
          : '';

      break;
    }

    case 'ApplicationCreated': {
      values = {
        adminName,
        projectName,
        projectDate: moment(new Date(projectDate), API_DATE_FORMAT).format(shortDateMonthFormat)
      };
      text = 'applicationCreateAuditLog';

      break;
    }

    case 'RequestDeclined': {
      values = {
        adminName,
        employeeName
      };
      text = 'requestDeclinedAuditLog';

      break;
    }

    case 'RequestApproved': {
      values = {
        adminName,
        employeeName
      };
      text = 'requestApprovedAuditLog';
      break;
    }

    case 'IsHostAccountActivated': {
      values = {
        adminName
      };
      text = 'IsHostAccountActivated';
      break;
    }

    default:
      break;
  }

  return { text, values };
};

export const exportFormattedAuditLogs = ({
  t,
  auditLogs = [],
  dateFormat = 'DD/MM/YYYY',
  timeFormat = 'HH:mm',
  shortDateMonthFormat = 'DD/MM',
  title = 'auditLogs',
  getProfileFieldValue
}) => {
  const formattedData = auditLogs.reduce((prev, { type, data: payload, createdAt, ...rest }) => {
    const { text, values } = generateAuditLogBody({
      t,
      type,
      data: { type, data: JSON.parse(payload), createdAt, ...rest },
      timeFormat,
      shortDateMonthFormat,
      getProfileFieldValue
    });

    const details = (Object?.keys(values)?.length === 0 ? t(text) : t(text, values) || '')
      .replace(/<brs*[/]?>/gi, '\n')
      .replace(/<[^>]*>/gi, '');

    return [
      ...prev,
      {
        [t('createdAt')]: moment.utc(createdAt).local().format(`${dateFormat} ${timeFormat}`),
        [t('details')]: details
      }
    ];
  }, []);

  exportExcel({ baseData: formattedData, name: t(title) });
};
