import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { useCallback, useState } from 'react';
import useToaster from '@ubeya/shared/hooks/useToaster';

const firebaseConfig = {
  apiKey: 'AIzaSyCo0vsRB731JGTwtjEDO_y8vzuHJtkfA0g',
  authDomain: 'ottavio-admin-login.firebaseapp.com',
  projectId: 'ottavio-admin-login',
  storageBucket: 'ottavio-admin-login.appspot.com',
  messagingSenderId: '140077801574',
  appId: '1:140077801574:web:93431e7b6b1f414fb1cda7'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const useFirebaseLogin = () => {
  const { addResponseError } = useToaster();
  const [err, setErr] = useState();
  const [result, setResult] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onSuccess = useCallback((res) => setResult(res), []);
  const onError = useCallback(
    (error) => {
      setErr(error);
      addResponseError(error);
    },
    [addResponseError]
  );
  const onFinally = useCallback(() => setIsLoading(false), []);

  const googleProvider = new GoogleAuthProvider();
  const signInWithGoogle = async () => {
    setIsLoading(true);
    signInWithPopup(auth, googleProvider).then(onSuccess).catch(onError).finally(onFinally);
  };

  return { signInWithGoogle, err, result, isLoading };
};

export default useFirebaseLogin;
