import { useMemo } from 'react';

const useViewportColumns = ({
  rawColumns,
  columnWidths,
  viewportWidth,
  scrollLeft,
  sortable,
  resizable,
  allowFrozenUntilWidth
}) => {
  const defaultSortable = sortable;
  const defaultResizable = resizable;
  const allowFrozen = allowFrozenUntilWidth ? viewportWidth >= allowFrozenUntilWidth : true;

  const { columns, lastFrozenColumnIndex } = useMemo(() => {
    let preparedLastFrozenColumnIndex = -1;

    const parsedColumns = rawColumns.map((rawColumn) => {
      const frozen = allowFrozen ? rawColumn.frozen || false : false;

      const column = {
        ...rawColumn,
        idx: 0,
        frozen,
        isLastFrozenColumn: false,
        sortable: rawColumn.sortable ?? defaultSortable,
        resizable: rawColumn.resizable ?? defaultResizable
      };

      if (frozen) {
        // eslint-disable-next-line no-plusplus
        preparedLastFrozenColumnIndex++;
      }

      return column;
    });

    parsedColumns.sort(({ frozen: frozenA }, { frozen: frozenB }) => {
      // Sort frozen columns first:
      if (frozenA) {
        if (frozenB) return 0;
        return -1;
      }
      if (frozenB) return 1;

      // Sort other columns last:
      return 0;
    });

    parsedColumns.forEach((column, idx) => {
      column.idx = idx;
    });

    if (preparedLastFrozenColumnIndex !== -1) {
      parsedColumns[preparedLastFrozenColumnIndex].isLastFrozenColumn = true;
    }

    return {
      columns: parsedColumns,
      lastFrozenColumnIndex: preparedLastFrozenColumnIndex
    };
  }, [rawColumns, allowFrozen, defaultSortable, defaultResizable]);

  const { layoutCssVars, totalColumnWidth, totalFrozenColumnWidth, columnMetrics } = useMemo(() => {
    const columnMetricsMap = new Map();
    let left = 0;
    let templateColumns = '';
    let allocatedWidth = 0;
    let unassignedColumnsCount = 0;
    const result = {
      totalColumnWidth: 0,
      totalFrozenColumnWidth: 0
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const column of columns) {
      let width = getSpecifiedWidth(column, columnWidths, viewportWidth);

      if (width === undefined) {
        unassignedColumnsCount += column.flexGrow ?? 1;
      } else {
        width = clampColumnWidth(width, column, false);
        allocatedWidth += width;
        columnMetricsMap.set(column, { width, left: 0 });
      }
    }
    let unallocatedWidth = viewportWidth - allocatedWidth;
    let unallocatedColumnWidth = unallocatedWidth / unassignedColumnsCount;

    // eslint-disable-next-line no-restricted-syntax
    for (const column of columns) {
      if (!columnMetricsMap.has(column)) {
        const width = clampColumnWidth(unallocatedColumnWidth, column);
        if (width !== unallocatedColumnWidth) {
          allocatedWidth += width;
          // eslint-disable-next-line no-plusplus
          unassignedColumnsCount -= column.flexGrow ?? 1;
        }
      }
    }

    unallocatedWidth = viewportWidth - allocatedWidth;
    unallocatedColumnWidth = unallocatedWidth / unassignedColumnsCount;

    // eslint-disable-next-line no-restricted-syntax
    for (const column of columns) {
      let width;
      if (columnMetricsMap.has(column)) {
        const columnMetric = columnMetricsMap.get(column);
        columnMetric.left = left;
        ({ width } = columnMetric);
      } else {
        width = clampColumnWidth(unallocatedColumnWidth, column);
        columnMetricsMap.set(column, { width, left });
      }
      result.totalColumnWidth += width;
      left += width;
      templateColumns += `${width}px `;
    }

    if (lastFrozenColumnIndex !== -1) {
      const columnMetric = columnMetricsMap.get(columns[lastFrozenColumnIndex]);
      result.totalFrozenColumnWidth = columnMetric.left + columnMetric.width;
    }

    const layoutCss = {
      '--template-columns': templateColumns
    };

    for (let i = 0; i <= lastFrozenColumnIndex; i++) {
      const column = columns[i];
      layoutCss[`--frozen-left-${column.dataKey}`] = `${columnMetricsMap.get(column).left}px`;
    }

    return {
      layoutCssVars: layoutCss,
      totalColumnWidth: result.totalColumnWidth,
      totalFrozenColumnWidth: result.totalFrozenColumnWidth,
      columnMetrics: columnMetricsMap
    };
  }, [columnWidths, columns, viewportWidth, lastFrozenColumnIndex]);

  const [colOverscanStartIdx, colOverscanEndIdx] = useMemo(() => {
    // get the viewport's left side and right side positions for non-frozen columns
    const viewportLeft = scrollLeft + totalFrozenColumnWidth;
    const viewportRight = scrollLeft + viewportWidth;
    // get first and last non-frozen column indexes
    const lastColIdx = columns.length - 1;
    const firstUnfrozenColumnIdx = Math.min(lastFrozenColumnIndex + 1, lastColIdx);

    // skip rendering non-frozen columns if the frozen columns cover the entire viewport
    if (viewportLeft >= viewportRight) {
      return [firstUnfrozenColumnIdx, firstUnfrozenColumnIdx];
    }

    // get the first visible non-frozen column index
    let colVisibleStartIdx = firstUnfrozenColumnIdx;
    while (colVisibleStartIdx < lastColIdx) {
      const { left, width } = columnMetrics.get(columns[colVisibleStartIdx]);
      // if the right side of the columnn is beyond the left side of the available viewport,
      // then it is the first column that's at least partially visible
      if (left + width > viewportLeft) {
        break;
      }
      // eslint-disable-next-line no-plusplus
      colVisibleStartIdx++;
    }

    // get the last visible non-frozen column index
    let colVisibleEndIdx = colVisibleStartIdx;
    while (colVisibleEndIdx < lastColIdx) {
      const { left, width } = columnMetrics.get(columns[colVisibleEndIdx]);
      // if the right side of the column is beyond or equal to the right side of the available viewport,
      // then it the last column that's at least partially visible, as the previous column's right side is not beyond the viewport.
      if (left + width >= viewportRight) {
        break;
      }
      // eslint-disable-next-line no-plusplus
      colVisibleEndIdx++;
    }

    return [Math.max(firstUnfrozenColumnIdx, colVisibleStartIdx - 1), Math.min(lastColIdx, lastColIdx)];
  }, [columns, columnMetrics, lastFrozenColumnIndex, scrollLeft, totalFrozenColumnWidth, viewportWidth]);

  const viewportColumns = useMemo(() => {
    const result = [];
    for (let colIdx = 0; colIdx <= colOverscanEndIdx; colIdx++) {
      const column = columns[colIdx];

      // eslint-disable-next-line no-continue
      if (colIdx < colOverscanStartIdx && !column.frozen) continue;
      result.push(column);
    }

    return result;
  }, [colOverscanEndIdx, colOverscanStartIdx, columns]);

  return {
    columns,
    viewportColumns,
    layoutCssVars,
    columnMetrics,
    totalColumnWidth,
    lastFrozenColumnIndex,
    totalFrozenColumnWidth
  };
};

const getSpecifiedWidth = ({ dataKey, width }, columnWidths, viewportWidth) => {
  if (columnWidths.has(dataKey)) {
    // Use the resized width if available
    return columnWidths.get(dataKey);
  }
  if (typeof width === 'number') {
    return width;
  }
  if (typeof width === 'string' && /^\d+%$/.test(width)) {
    return Math.floor((viewportWidth * parseInt(width, 10)) / 100);
  }
  return undefined;
};

const clampColumnWidth = (width, { flexGrow = 1, minWidth, maxWidth = Infinity }, considerFlexGrow = true) => {
  let newWidth = width;
  if (width && width < 0) {
    newWidth = 200;
  }

  const totalWidth = newWidth ? newWidth * (considerFlexGrow ? flexGrow : 1) : 0;
  return Math.min(Math.max(totalWidth, minWidth ?? 100), maxWidth);
};

export default useViewportColumns;
