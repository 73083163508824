import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { FlexColumnCenter, LoaderArea } from '@ubeya/shared-web/components';
import useAuth from '../hooks/useAuth';

const Wrapper = styled(FlexColumnCenter)`
  height: 100vh;
`;

const AutoLogin = () => {
  const { token } = useParams();
  const { push } = useHistory();
  const { loginAsUser } = useAuth();

  const autoLogin = useCallback(async () => {
    try {
      await loginAsUser({ token });
    } catch (err) {
      push('/');
    }
  }, [loginAsUser, push, token]);

  useEffect(() => {
    autoLogin();
  }, [autoLogin]);

  return (
    <Wrapper>
      <LoaderArea />
    </Wrapper>
  );
};

export default AutoLogin;
