import React, { useContext, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import BaseLabel from '../Label';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { CircleIcon, TextareaAutosize, flexCenter } from '..';
import ConfigContext from '../../contexts/ConfigContext';
import { TextPreviewModal } from '../Modal/TextPreviewModal';

const Label = styled(BaseLabel)`
  ${({ $enablePreviewModal }) =>
    $enablePreviewModal &&
    css`
      ${flexCenter};
      flex-direction: row;
    `}
`;

const TitleCountWrapper = styled.div.attrs(() => ({ className: 'title-count-wrapper' }))`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
`;

export const TitleWithCharsCount = ({ title, value = '', maxLength = 250 }) => {
  const length = value.length;

  return (
    <TitleCountWrapper>
      <div>{title}</div>
      <div>
        {length}/{maxLength}
      </div>
    </TitleCountWrapper>
  );
};

const TextArea = ({
  title: baseTitle,
  name,
  value,
  maxLength,
  onChange,
  error,
  className,
  required,
  innerRef,
  showResizeIcon = true,
  showCharsCount = false,
  enablePreviewModal = false,
  ...inputProps
}) => {
  const { t } = useContext(ConfigContext);
  const [showPreview, setShowPreview] = useState(false);

  const inputRef = useRef();
  const textAreaRef = innerRef || inputRef;

  const title = showCharsCount ? (
    <TitleWithCharsCount title={baseTitle} value={value} maxLength={inputProps.maxLength} />
  ) : (
    baseTitle
  );

  const inputParams = {
    id: name,
    value,
    maxLength,
    onChange: (e) => onChange(e.target.value),
    error,
    showResizeIcon,
    ref: textAreaRef,
    ...inputProps
  };

  return (
    <Label
      title={title}
      required={required}
      className={className}
      error={error}
      showCharsCount={showCharsCount}
      $enablePreviewModal={enablePreviewModal}>
      <TextareaAutosize {...inputParams} />
      {enablePreviewModal && <CircleIcon tooltip={t('edit')} icon={EditIcon} onClick={() => setShowPreview(true)} />}

      {showPreview && (
        <TextPreviewModal onClose={() => setShowPreview(false)} textAreaRef={textAreaRef} inputParams={inputParams} />
      )}
    </Label>
  );
};

export default TextArea;
