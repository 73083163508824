import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useQueryParams } from '@ubeya/shared-web/hooks/useQueryParams';
import { media } from '@ubeya/shared-web/style';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import AuthLayout from './AuthLayout';
import AdminLogin from './AdminLogin';

const BASE_CREW_URL = process.env.CREW_URL;

const UserText = styled.div`
  margin-right: 5px;
`;

const UserOption = styled.a`
  text-align: center;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
  border-bottom: 2px solid transparent;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  ${media.mobile`
    ${({ hideOnWeb }) => hideOnWeb && 'display: none;'};
  `}
`;

const OtherLoginOptions = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const UserContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Login = () => {
  const { redirectUrl } = useQueryParams();
  const { t } = useContext(ConfigContext);
  const onUserOptionClick = useCallback(
    () => (window.location = redirectUrl ? decodeURIComponent(redirectUrl) : BASE_CREW_URL),
    [redirectUrl]
  );

  return (
    <AuthLayout>
      <AdminLogin />
      <OtherLoginOptions>
        <UserContainer>
          <UserText>{t('employee')}?</UserText>
          <UserOption onClick={onUserOptionClick}>{t('clickHere')}</UserOption>
        </UserContainer>
        {/* <UserContainer>
            <UserText>{t('dontHaveAnAccount')}</UserText>
            <UserOption onClick={onDontHaveAccClick}>{t('signUp')}</UserOption>
          </UserContainer> */}
      </OtherLoginOptions>
    </AuthLayout>
  );
};

export default Login;
