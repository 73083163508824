import styled from 'styled-components';
import { media } from '@ubeya/shared-web/style';
import {
  Button,
  FlexColumn,
  InputField as BaseInputField,
  Loader as BaseLoader,
  NumberField
} from '@ubeya/shared-web/components';
import BaseBox from './Box';

export const Box = styled(BaseBox)`
  margin-top: 10px;
  width: 100%;

  .box-title {
    ${media.tablet`margin: 0 0 43px;`}
    font-size: 36px;
    line-height: 1.5;
    text-align: center;
  }
`;

export const Loader = styled(BaseLoader)`
  .lds-ring div {
    border: 3px solid ${({ theme }) => theme.colors.surface};
    border-color: ${({ theme }) => theme.colors.surface} transparent transparent transparent;
  }
`;

export const InputField = styled(BaseInputField)`
  input {
    border: solid 1px #cccccc;
  }
  background-color: ${({ theme }) => theme.colors.surface};
`;

export const ButtonsContainer = styled(FlexColumn)`
  width: 100%;

  > :not(style) ~ :not(style) {
    margin-top: 12px;
  }
`;

export const PrimaryRoundButton = styled(Button)`
  height: 45px;
  flex-grow: 0;
  border-radius: 52px;
  color: ${({ theme }) => theme.colors.surface};
  background-color: ${({ theme, isLoading }) => (isLoading ? '#4042a8' : theme.colors.primary)};

  &:hover {
    background-color: #4042a8;
  }
`;

export const DefaultRoundButton = styled(Button)`
  height: 45px;
  flex-grow: 0;
  border-radius: 52px;
  background-color: transparent;
  border: solid 1px #cccccc;
`;

export const SecondaryRoundButton = styled(Button)`
  height: 45px;
  flex-grow: 0;
  border-radius: 52px;
  background-color: transparent;
`;

export const NumberFieldNoArrows = styled(NumberField)`
  * {
    width: 100%;
  }
  button {
    display: none;
  }
  input {
    text-align: left;
  }
`;
