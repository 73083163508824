const defaultColors = {
  primary: '#645df6',
  primaryLight: '#8278ff',
  primarySemiLight: '#6659FF',
  primaryDark: '#5f53ed',
  primaryLighter: '#CACBFF',
  primaryGradient: 'linear-gradient(227deg, #6659ff, #c3beff 4%, #c3beff 4%)',
  secondary: '#10B07C',
  background: '#fafcff',
  backgroundLight: '#f4f7ff',
  backgroundLight1: '#c5c7d0',
  backgroundDark: '#050849',
  sidebarHover: '#FFFFFF26',
  sidebarMenuBorder: '#E3E7EF',
  topbarMenuSelected: '#E1E2E9',
  accountNoImageBackground: '#5E89F9',
  mainSideBar: '#262f5b',
  surface: '#ffffff',
  success: '#44d7b6',
  successSecondary: '#10B07C',
  successLight: '#44d7b65e',
  successLighten1: '#44d7b600',
  primaryApprove: 'rgb(68, 215, 182)',
  calendarToday: '#D7E6FB',
  successDark: '#17ab89',
  failure: '#ff6163',
  failureLight: '#ff61630d',
  failureLighten1: '#eb3953',
  failureLighten2: '#eb39530d',
  failureLighten3: '#ff6464',
  warning: '#f7b500',
  info: '#0091ff',
  info2: '#fd61a9',
  lightPurple: '#5E89F9',
  booked: 'rgb(68, 215, 182,0.2)',
  selectedShiftIndicator: '#6467ef',
  outsource: '#ff8833',
  outsourceBlue: '#4642b8',
  tableBorder: '#f0efef',
  modalBackground: '#fffefe',
  modalBorder: '#dcdfec',
  border: '#D9DCE0',
  skeletonBackground: '#E0E5ED',
  palette: [
    // { light: '#fff2d9', dark: '#d28313', gradient: 'linear-gradient(to left, #ffce73 83%, #ffefd2 1%)' },
    // { light: '#d5f5ff', dark: '#2290b0', gradient: 'linear-gradient(to right, #e0f9ff 0%, #a0d7e7 70%)' },
    // { light: '#d4d0ff', dark: '#4825d2', gradient: 'linear-gradient(to right, #b091f4 -12%, #4825d2 112%)' },
    // { light: '#ffd4e2', dark: '#ee3873', gradient: 'linear-gradient(to right, #ffdfeb -4%, #ff5d91 126%)' },
    // { light: '#e5ebf2', dark: '#798a9e', gradient: 'linear-gradient(to right, #c5ccd4 -20%, #798a9e 109%)' },
    // { light: '#ecd6ff', dark: '#9720e0', gradient: 'linear-gradient(to right, #e7b0f9 -20%, #9720e0 116%)' },
    // { light: '#e7f0ff', dark: '#1c66f2', gradient: 'linear-gradient(to bottom, #e4f2ff 0%, #1c66f2)' },
    // { light: '#ffe6d0', dark: '#df6a0c', gradient: 'linear-gradient(to left, #ee750f 93%, #ffeada 1%)' }
    { light: '#E8F7F4', dark: '#15AB93' },
    { light: '#EBF4FD', dark: '#3B8EEC' },
    { light: '#FCF1E9', dark: '#E07020' },
    { light: '#EFEEFD', dark: '#5E51E8' },
    { light: '#FAEBF6', dark: '#CF39A3' },
    { light: '#F4EDFB', dark: '#954AD3' },
    { light: '#FCF5E9', dark: '#E09E23' },
    { light: '#F1F8EF', dark: '#76B45C' },
    { light: '#EAEAF3', dark: '#2C2E85' },
    { light: '#FBEBEE', dark: '#DB3A61' }
  ],

  progressBar: {
    background: '#dcdfec',
    green: '#3ac880'
  },

  eventLabel: { light: '#e7f0ff', dark: '#1c66f2' },

  chatPalette: {
    footerBackground: '#f5f7fe',
    unreadBadge: '#fe3e6d',
    readBadge: '#707991'
  },

  reportPalette: [
    '#6659ff',
    '#94d8ec',
    '#ff78a3',
    '#ffce74',
    '#ffa55e',
    '#bc6cee',
    '#70a2ff',
    '#a7b2c0',
    '#bbf0ff',
    '#ad98fe',
    '#8085f0',
    '#ffbdd2'
  ],

  schedulingPalette: {
    bookedConfirmed: '#15AB93',
    fullyConfirmed: '#10B07C',
    fullyBooked: '#10B07C',
    booked: 'rgb( 21, 171, 147,0.10)',
    openShift: '#cbd2e0',
    notBooked: '#E44258',
    warning: '#f24f63',
    paritalBooked: '#FEB11F',
    full: '#0acf84',
    partialBooked: '#FEB11F',
    overbookDark: '#AFCDF7',
    overbookMid: '#E0EDFF',
    overbookLight: '#F3F8FE'
  },
  exploreEmployees: {
    labelBackground: 'rgba(37, 37, 37, 0.08)'
  },
  positions: {
    labelBackground: '#f4f4f4',
    summaryBackground: '#d1d2fb',
    badge: '#e7e8eb',
    day: 'rgba(100, 103, 239, 0.3)',
    dayHover: 'rgba(100, 103, 239, 0.5)',
    daySelected: '#6467ef',
    month: 'rgba(44, 46, 133, 0.24)',
    disabledIcon: '#d5d4d4',
    dropdownButton: '#f4f7ff'
  },

  hourlyViewPalette: {
    timeoffBackground: 'rgba(100, 147, 239, 0.1)',
    timeoff: 'rgba(100, 147, 239)',
    blocked: 'rgba(201, 201, 201, 0.1)',
    preferToWork: 'rgba(70, 204, 136, 0.1)',
    preferNotToWork: 'rgb(255, 97, 99, 0.1)',
    preferToWorkSolid: '#10B07C',
    preferNotToWorkSolid: '#ff6163',
    available: '#ffffff',
    partialAvailable: '#ff8833',
    unConfirmed: '#d5d4d4',
    bookedConfirmed: '#44d7b6',
    booked: '#c7efdb',
    openShift: '#7d7d7d',
    openShiftBackground: 'rgba(201, 201, 201, 0.17)'
  },

  fields: {
    toggle: '#10B07C',
    drawer: '#f8f8f8'
  },

  table: {
    lightHover: '#F5F6F8',
    darkHover: '#E8ECF3',
    summarized: '#F5F6F8'
  },

  bookingBoard: {
    available: '#10B07C',
    isConfirmed: '#10B07C',
    hasWarnings: '#ff6163',
    default: '#c9c9c9',
    isConfirmedBackground: 'rgba(70, 204, 136, 0.05)',
    approved: '#10B07C'
  },

  liveView: {
    onBreak: '#211C62'
  },
  compliance: {
    full: '#00ba8d',
    partial: '#e9a11b',
    none: '#E8E8E8',
    plus: '#f6f6f6',

    expired: {
      light: 'rgb(255,100, 100,0.10)',
      dark: '#ff6464',
      hover: {
        light: 'rgb(255,100, 100,0.25)',
        dark: '#FE6464'
      }
    },
    approved: {
      light: 'rgb( 0,186, 141,0.10)',
      dark: '#00BA8D',
      hover: {
        light: 'rgb(0,186, 141,0.25)',
        dark: '#00BA8D'
      }
    },
    pending: {
      light: 'rgb(254,177, 31,0.10)',
      dark: '#FEB11F',
      hover: {
        light: 'rgb(254, 177, 31, 0.25)',
        dark: '#FEB11F'
      }
    },
    declined: {
      light: 'rgb(255,100, 100,0.10)',
      dark: '#FE6464',
      hover: {
        light: 'rgb(255,100, 100,0.25)',
        dark: '#FE6464'
      }
    }
  },
  eventModal: {
    labelBackground: '#f5f6f8',
    overbookingShift: '#E1EDFF',
    icon: '#5e5e66',
    toggleAllBorder: 'rgba(37, 37, 37, 0.4)',
    detailsBackground: '#f5f6f8',
    purple: '#6467ef',
    avatarBackground: '#ebebeb',
    avatarText: '#6467EF',
    ageWarning: '#C23F4F'
  },

  filtersTags: '#e1e6ee',

  dashboard: {
    green: '#3ac880',
    purple: '#6467ef',
    darkPurple: '#5052BF',
    progressBackground: '#D1D1FA',
    profitAndLossPrimary: '#6467ef',
    profitAndLossBorder: '#dcdfec'
  },

  video: {
    purple: '#6467ef',
    gray: '#D1D1FA'
  },

  orderDetailsModal: {
    changed: 'rgba(100, 103, 239, 0.15)',
    label: '#ececec',
    border: '#ececec'
  },

  supplierModal: {
    border: '#ececec',
    label: 'rgba(215, 217, 222, 0.4);'
  },

  generalAvailability: {
    available: '#10B07C',
    notAvailable: '#ff0000',
    approvedBackground: 'rgba(70,204 ,136, 0.08)',
    pendingBackground: 'rgba(255, 136, 51, 0.08)',
    pending: '#ff8833',
    approve: '#6467ef'
  },
  workflow: {
    black: '#000000',
    grey: 'grey',
    lightGrey: '#e2e3e3',
    purple: '#6658FF'
  },
  generalScheduling: {
    isToday: '#F5F7FF'
  },
  billingPalette: {
    myPlan: '#6467ef30',
    myPlanEdit: '#E8EBF0',
    separator: '#647aef40',
    separatorEdit: '#6768794d',
    minimumMonthlyPayment: '#191919'
  },
  gray000: '#000000',
  gray100: '#424242',
  gray200: '#252525',
  gray300: '#858585',
  gray400: '#979797',
  gray500: '#BFBFBF',
  gray600: '#D7D9DE',
  gray700: '#D9D9D9',
  gray800: '#DCDCDC',
  gray900: '#E8E8E8',
  gray910: '#F2F3F5',
  gray920: '#FBFDFE',
  gray930: '#eee',
  tabLink: '#777885',
  tabHoverBackground: '#E9ECF3',
  grayBorder: '#7d7d7d',
  link: '#016dff',
  linkDark: '#0b73ff',
  error: '#e22212',
  inputShadow: '0 0 3px 1px rgba(78, 57, 255, 0.25);',
  darkDivider: '#3D3FA2',
  whitesmoke: '#f5f5f5',
  primaryOrange: '#FF8833',
  primaryLight2: '#efefef'
};

const themeMaker = (themeColors = {}) => {
  const colors = { ...defaultColors, ...themeColors };

  return {
    colors,
    button: {
      primary: {
        normal: {
          background: colors.primary,
          color: colors.surface,
          border: 'transparent'
        },
        hover: {
          background: colors.primaryLight,
          color: colors.surface,
          border: 'transparent'
        },
        disabled: {
          background: colors.gray600,
          color: colors.surface,
          border: 'transparent'
        },
        pressed: {
          background: colors.primaryDark
        }
      },
      secondary: {
        normal: {
          background: colors.surface,
          color: colors.primary,
          border: colors.primary
        },
        hover: {
          background: colors.surface,
          color: colors.primary,
          border: colors.primary,
          shadow: colors.inputShadow
        },
        disabled: {
          background: colors.surface,
          color: colors.gray600,
          border: colors.gray600
        },
        pressed: {
          background: colors.surface
        }
      },
      tertiary: {
        normal: {
          background: colors.surface,
          color: colors.primary,
          border: 'transparent'
        },
        hover: {
          background: colors.background,
          color: colors.primary,
          border: 'transparent'
        },
        disabled: {
          background: colors.surface,
          color: colors.gray400,
          border: 'transparent'
        },
        pressed: {
          background: colors.background
        }
      },
      quaternary: {
        general: {
          padding: '13px 73px'
        },
        normal: {
          background: colors.primary,
          color: colors.surface,
          border: colors.surface
        },
        hover: {
          background: colors.primaryLight,
          color: colors.surface,
          border: colors.surface
        },
        disabled: {
          background: colors.gray600,
          color: colors.surface,
          border: colors.surface
        },
        pressed: {
          background: colors.primaryDark
        }
      },
      success: {
        normal: {
          background: colors.success,
          color: colors.surface,
          border: 'transparent'
        },
        hover: {
          background: colors.successDark,
          color: colors.surface,
          border: 'transparent'
        },
        disabled: {
          background: colors.successLight,
          color: colors.surface,
          border: 'transparent'
        },
        pressed: {
          background: colors.successDark
        }
      },
      link: {
        general: {
          borderWidth: '0 0 1px 0',
          padding: 0,
          borderRadius: 0
        },
        normal: {
          background: 'transparent',
          color: colors.primary,
          border: colors.primary
        },
        hover: {
          background: 'transparent',
          color: colors.primaryDark,
          border: colors.primaryDark
        },
        disabled: {
          background: 'transparent',
          color: colors.dark,
          border: colors.dark
        }
      },
      secondaryGray: {
        general: {
          borderRadius: '2px'
        },
        normal: {
          background: colors.surface,
          color: colors.gray200,
          border: '#ccc'
        },
        hover: {
          background: colors.surface,
          color: colors.gray200,
          border: '#ccc'
        },
        disabled: {
          background: colors.surface,
          color: colors.gray600,
          border: colors.gray600
        },
        pressed: {
          background: colors.whitesmoke
        }
      }
    }
  };
};

export default themeMaker;
