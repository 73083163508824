import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { get, post } from '@ubeya/shared/utils/http';
import { FlexColumnCenter, LoaderArea } from '@ubeya/shared-web/components';

const Wrapper = styled(FlexColumnCenter)`
  height: 100vh;
`;

const CheckVersion = () => {
  const { replace } = useHistory();

  const fetchUser = useCallback(async () => {
    try {
      await get(`${process.env.API_WEB_URL}/auth/me`);
      window.location = process.env.WEB_URL;
    } catch (err) {
      try {
        await post('https://www.ubeya.com/api/admin/getUserNew');
        window.location = 'https://www.ubeya.com/login';
      } catch (error) {
        replace('/login');
      }
    }
  }, [replace]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <Wrapper>
      <LoaderArea />
    </Wrapper>
  );
};

export default CheckVersion;
