import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import useAccount from '@ubeya/shared/hooks/account/useAccount';
import { useHistory } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { useSetRecoilState } from 'recoil';
import { openHelpCenter, plansModal } from '@ubeya/shared/atoms/shared';
import { ReactComponent as BaseCloseIcon } from '@ubeya/shared-web/assets/close.svg';
import { ReactComponent as BasePoweredByUbeyaIcon } from '@ubeya/shared-web/assets/logo-ubeya.svg';
import BaseModal from '../Modal';
import { Header2 } from '../Typography';
import ConfigContext from '../../contexts/ConfigContext';
import { PLANS } from '../../constants/billing.constants';

const Modal = styled(BaseModal)`
  background-color: #f8f9fa;
  width: 100vw;
  height: 100vh;
  max-width: 1100px;
  max-height: 900px;
`;

// const Iframe = styled.iframe`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   border: none;
// `;

const CardsWrapper = styled.div`
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  align-self: center;
  ${({ $isMobileOnly }) =>
    $isMobileOnly
      ? css`
          flex-direction: column;
          gap: 10px;
        `
      : css`
          margin-top: 40px;
        `}
`;

const Card = styled.div`
  flex-direction: column;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);

  display: flex;
  position: relative;
  border: 1px solid #e8e8fd;
  border-radius: 10px;
  margin: 0 5px;
  margin-top: ${({ $showRecommended, $isMobileOnly }) => $showRecommended && $isMobileOnly && '40px'};
  box-shadow: ${({ $showRecommended }) => ($showRecommended ? '0 1px 30px rgba(64, 66, 168, 0.23)' : 'none')};
`;

const Content = styled.div`
  width: 245px;
  height: 500px;

  flex-direction: column;
  justify-content: flex-start;
  align-self: center;

  padding: 20px;
  display: flex;
  position: static;
`;

const Type = styled.div`
  color: #252525;
  text-align: left;
  align-self: flex-start;
  margin-top: 10px;
  padding-top: 0;
  font-size: 20px;
  font-weight: 500;
`;

const Pricing = styled.div`
  margin-bottom: 9%;
  margin-top: 25px;
  margin-bottom: 4%;
  font-size: 50px;
  font-weight: 500;
`;

const TextBlock = styled.div`
  align-self: flex-start;
  margin: 5% 0;
  font-size: 14px;
  font-weight: 400;
`;

const ButtonBase = styled.div`
  color: #6467ef;
  background-color: #fff;
  border: 2px solid #6467ef;
  border-radius: 10px;
  font-size: 18px;

  padding: 10px 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  align-self: center;
`;

const Button = styled(ButtonBase)`
  position: absolute;
  bottom: 20px;
  ${({ $showRecommended }) =>
    $showRecommended
      ? css`
          background-color: #6467ef;
          color: #fff;
          cursor: pointer;
        `
      : css`
          &:hover {
            background-color: #6467ef;
            color: #fff;
            cursor: pointer;
          }
        `}
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-size: 35px;
  font-weight: 500;
  line-height: 1.3;
  margin: 30px 0;
  text-align: ${({ $isMobileOnly }) => $isMobileOnly && 'center'};
`;
const FeaturesList = styled.ul``;

const PoweredByUbeyaIcon = styled(BasePoweredByUbeyaIcon)`
  height: 50px;
  width: 120px;
`;

const ListItem = styled.li`
  text-align: left;
  padding-left: 2px;
  text-indent: 0;
  margin-bottom: 2px;
  line-height: 25px;
`;

const RecommendedWrapper = styled.div`
  position: absolute;
  top: -35px;
  background-color: #e8e8fd;
  width: 100%;
  color: #6467ef;
  font-size: 16px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const ButtonsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

const CloseIcon = styled(BaseCloseIcon)`
  position: absolute;
  right: 30px;
  top: 30px;

  cursor: pointer;
`;

const HelpCenterButton = styled(ButtonBase)`
  &:hover {
    background-color: #6467ef;
    color: #fff;
  }
`;

const SubTitle = styled(Header2)`
  padding-top: 10px;
  text-align: center;
  margin-bottom: 40px;
  line-height: 30rem;
`;

const ButtonsText = styled(SubTitle)`
  margin: 10px;
  align-self: center;
`;

const MainTitle = () => (
  <TitleWrapper $isMobileOnly={isMobileOnly}>
    <PoweredByUbeyaIcon />
    Choose the right plan for you
  </TitleWrapper>
);

const SupplierFreemiumPlansModal = () => {
  const { accountId } = useAccount();

  const setIsOpenHelpCenter = useSetRecoilState(openHelpCenter);
  const setIsPlansModalOpen = useSetRecoilState(plansModal);

  const { push } = useHistory();
  const { t } = useContext(ConfigContext);

  const onClose = () => setIsPlansModalOpen(false);

  const onUpgrade = (selectedPlan = '') => {
    onClose();
    push(`/${accountId}/billing?plan=${selectedPlan.toLowerCase()}`);
  };

  return (
    <Modal showCloseIcon onClose={onClose} showConfirmButton={false} closeOnClickOutside={false} size="xlarge">
      <MainTitle />

      <CloseIcon onClick={onClose} />

      <CardsWrapper $isMobileOnly={isMobileOnly}>
        {(PLANS || []).map(({ name, price, features = [], upgradeLink, showRecommended }, index) => (
          <Card key={index} $isMobileOnly={isMobileOnly} $showRecommended={showRecommended}>
            {showRecommended && <RecommendedWrapper>Recommended for you</RecommendedWrapper>}
            <Content>
              <Type>{name}</Type>
              <Pricing>{price}</Pricing>
              <TextBlock>What's included:</TextBlock>

              <FeaturesList>
                {features.map((featureName, indexFeature) => (
                  <ListItem key={indexFeature}>{featureName}</ListItem>
                ))}
              </FeaturesList>
              {upgradeLink && (
                <Button onClick={() => onUpgrade(name)} $showRecommended={showRecommended}>
                  Upgrade
                </Button>
              )}
            </Content>
          </Card>
        ))}
      </CardsWrapper>
      <ButtonsText>{t('upgradeModalContact')}</ButtonsText>
      <ButtonsContainer>
        <HelpCenterButton
          secondary
          narrow
          onClick={() => {
            setIsOpenHelpCenter(true);
          }}>
          {t('upgradeModalContactUs')}
        </HelpCenterButton>
      </ButtonsContainer>
      {/* <Iframe src={`https://main.ubeya.com/lp/pricing-ottavio-pop-up?accountId=${accountId}`} /> */}
    </Modal>
  );
};

export default SupplierFreemiumPlansModal;
