import React, { useEffect } from 'react';
import useAuth from 'app/hooks/useAuth';

const Logout = () => {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default Logout;
