import styled from 'styled-components';
import React, { useContext } from 'react';
import { ORDER_STATUSES } from '@ubeya/shared/constants';
import { default as BaseChip } from '../Chip';
import ConfigContext from '../../contexts/ConfigContext';
import { TextWithTooltip } from '../Tooltip';

const Wrapper = styled(BaseChip)`
  padding: 4px 12px;
  font-weight: 300;
  font-size: 14px;
  border-radius: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: fit-content;
  max-width: 100%;

  svg {
    min-width: 24px;
  }
`;

export const OrderStatusChip = ({ status }) => {
  const { t } = useContext(ConfigContext);

  const orderStatus = Object.values(ORDER_STATUSES).find(({ label }) => label === status);

  if (!orderStatus) {
    return status;
  }

  return (
    <TextWithTooltip>
      <Wrapper
        color={orderStatus.colorLight || orderStatus.color}
        background={orderStatus.backgroundLight}
        label={t(orderStatus.slug)}
      />
    </TextWithTooltip>
  );
};
