import React, { useContext } from 'react';
import styled from 'styled-components';
import useFields, { FieldTypes } from '@ubeya/shared/hooks/account/useFields';
import { ReactComponent as BaseInfoIcon } from '@ubeya/shared-web/assets/help.svg';
import { CheckBoxField } from '../FormFields';
import FormModal from '../FormModal';
import ConfigContext from '../../contexts/ConfigContext';

const HIDE_DYNAMIC_FIELDS = ['start-employment-date', 'end-employment-date', 'employee-id', 'residence-address'];

const BottomLink = styled.a`
  margin: 20px 0 0 20px;
`;

const Text = styled.div`
  margin: 0 0 10px 20px;
`;

const InfoIcon = styled(BaseInfoIcon)`
  width: 15px;
  height: 15px;
  margin-right: 8px;
`;

const ProfileInputModal = ({ accountId, onClose, onConfirm }) => {
  const { t } = useContext(ConfigContext);

  const { fields, mappedFields } = useFields();

  return (
    <FormModal
      validate={(values) =>
        Object.entries(values || {}).filter(([, value]) => !!value).length > 0 ? undefined : { required: true }
      }
      onSubmit={(values) => {
        const selectedFields = Object.entries(values || {})
          .filter(([, value]) => !!value)
          .map(([key]) => ({
            type: 0,
            ...mappedFields[parseInt(key.replace('profile-field-', ''), 10)],
            isRequired: true
          }));

        onConfirm(selectedFields);
        onClose();
      }}
      onClose={onClose}
      size="small"
      title={t('profileField')}
      confirmText="add"
      disabledOnInvalid>
      <Text>
        <InfoIcon />
        {t('profileFieldTooltip')}
      </Text>

      {fields?.length > 0 ? (
        fields
          .filter(({ slug }) => !HIDE_DYNAMIC_FIELDS.includes(slug))
          .filter(({ fieldTypeId }) => fieldTypeId !== FieldTypes.FIELD_TYPE_ADDRESS)
          .map(({ id, isGeneric, name, slug }) => (
            <CheckBoxField key={id} name={`profile-field-${id}`} title={isGeneric ? t(slug) : name} />
          ))
      ) : (
        <BottomLink href={`${accountId}/settings/account/employee-profile/fields`} target="_blank">
          {t('addFirstProfileField')}
        </BottomLink>
      )}
    </FormModal>
  );
};

export default ProfileInputModal;
