import { useMemo } from 'react';
import { createCachedSelector } from 're-reselect';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import * as api from '../../services/api';
import useCRUD from '../useCRUD';
import * as sharedAtoms from '../../atoms/shared';
import { SORT_BY_STRING } from '../../utils/sorting';
import useAccount from './useAccount';

const selector = createCachedSelector(
  (data) => data.data,
  (data, branchId) => branchId,
  (data, branchId) => {
    const labelSorter = SORT_BY_STRING('label');
    const nameSorter = SORT_BY_STRING('name');

    const locations = (data || [])
      .filter(({ branches, isSubLocation }) => {
        if (isSubLocation) {
          return true;
        }
        const currBranch = branches.find(({ id }) => id === branchId);

        if (!currBranch || currBranch.isDeleted) {
          return false;
        }
        return true;
      })
      .sort(nameSorter);

    const locationsOptions = (locations || [])
      .map(({ id, name, isSubLocation }) => ({ value: id, label: name, isSubLocation }))
      .sort(labelSorter);

    const mappedLocations = (data || []).reduce(
      (prev, { id, isSubLocation, ...location }) => ({
        ...prev,
        [id]: {
          ...location,
          id,
          isSubLocation,
          branches: isSubLocation ? undefined : location.branches.map((branch) => branch.id)
        }
      }),
      {}
    );

    return { locations, locationsOptions, mappedLocations };
  }
)({
  keySelector: (data, branchId, storeKey) => `${storeKey.join('#')}#${branchId}`
});

const useLocations = ({ branchId: customBranchId } = {}) => {
  const { accountId } = useAccount();
  const defaultBranchId = useRecoilValue(sharedAtoms.selectedBranch);
  const branchId = customBranchId ? parseInt(customBranchId, 10) : defaultBranchId;

  const storeKey = ['locations', accountId];
  const { isLoading, data, refetch } = useQuery(storeKey, () => api.fetchLocations({ accountId }), {
    select: (locationData) => selector(locationData, branchId, storeKey)
  });

  const { locations, locationsOptions, mappedLocations } = useMemo(() => {
    const {
      locations: rawLocations = [],
      locationsOptions: rawLocationsOptions = [],
      mappedLocations: rawMappedLocations = {}
    } = data || {};
    return {
      locations: rawLocations,
      locationsOptions: rawLocationsOptions,
      mappedLocations: rawMappedLocations
    };
  }, [data]);

  const { addItem, editItem, deleteItem } = useCRUD(
    storeKey,
    {
      addApi: (location) => api.addLocation({ accountId, branchId, location }),
      editApi: ({ id, ...location }) => api.updateLocation({ accountId, branchId, locationId: id, location }),
      deleteApi: ({ id }) => api.deleteLocation({ accountId, branchId, locationId: id })
    },
    { formatItem: (item) => ({ ...item, branches: [branchId] }) }
  );

  return {
    isLoading,
    locations,
    refetch,
    locationsOptions,
    mappedLocations,
    deleteLocation: deleteItem,
    addLocation: addItem,
    editLocation: editItem
  };
};

export default useLocations;
