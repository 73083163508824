import { useContext, useMemo } from 'react';
import getFormattingMap from '@ubeya/shared/utils/formatting';
import ConfigContext from '../contexts/ConfigContext';

const useFormattingMap = () => {
  const { t } = useContext(ConfigContext);
  const { dateFormat, timeFormat, currency, shortDateFormat } = useContext(ConfigContext);

  const formattingMap = useMemo(() => getFormattingMap({ dateFormat, timeFormat, currency, t, shortDateFormat }), [
    currency,
    dateFormat,
    t,
    shortDateFormat,
    timeFormat
  ]);

  return formattingMap;
};

export default useFormattingMap;
