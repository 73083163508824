import useOptimisticMutation from './useOptimisticMutation';

const useCRUD = (
  storeKey,
  { addApi, editApi, deleteApi },
  { onSuccess, refetchOnSuccessDelete = false, formatItem } = {},
  idProperty = 'id'
) => {
  const { mutateAsync: addItem } = useOptimisticMutation(
    storeKey,
    addApi,
    ({ previousData, ...item }) => ({
      ...previousData,
      data: [...previousData.data, formatItem?.(item) ?? item]
    }),
    { onSuccess }
  );

  const { mutateAsync: editItem } = useOptimisticMutation(
    storeKey,
    editApi,
    ({ previousData, ...newItem }) => ({
      ...previousData,
      data: previousData.data.map((item) =>
        item[idProperty] !== newItem[idProperty] ? item : formatItem?.(newItem) ?? newItem
      )
    }),
    { onSuccess }
  );

  const { mutateAsync: deleteItem } = useOptimisticMutation(
    storeKey,
    deleteApi,
    (data) => {
      const { previousData } = data;
      return {
        ...previousData,
        data: previousData.data.filter((item) => item[idProperty] !== data[idProperty])
      };
    },
    { refetchOnSuccess: refetchOnSuccessDelete, onSuccess }
  );

  return { addItem, editItem, deleteItem };
};

export default useCRUD;
