import React from 'react';
import { Button, Loader } from '@ubeya/shared-web/components';
import { useQueryParams } from '@ubeya/shared-web/hooks/useQueryParams';
import useAuth from '../hooks/useAuth';
import Box from './Box';

const Unsubscribe = () => {
  const { unsubscribe } = useAuth();
  const { email, token } = useQueryParams();

  return (
    <Box
      onSubmit={() => unsubscribe({ email, token })}
      title="Unsubscribe"
      subTitle="Would you like to unsubscribe from email updates?"
      linkTo="/login"
      linkText="">
      {({ submitting, valid, submitSucceeded }) => (
        <>
          {submitSucceeded ? (
            <span>You wer'e successfully unsubscribed</span>
          ) : (
            <>
              <Button primary disabled={submitting || !valid}>
                {submitting ? <Loader size="xsmall" /> : 'Unsubscribe me'}
              </Button>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default Unsubscribe;
