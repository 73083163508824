import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { required } from '@ubeya/shared/utils/form';
import usePersistedState from '@ubeya/shared-web/hooks/usePersistedState';
import { Button, Label as BaseLabel } from '@ubeya/shared-web/components';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import { ReactComponent as GoogleIcon } from '@ubeya/shared-web/assets/google.svg';
import { usePageTitle } from '@ubeya/shared/hooks/usePageTitle';
import useAuth from '../hooks/useAuth';
import { Box, PrimaryRoundButton, Loader, InputField } from './styled';
import useFirebaseLogin from 'app/hooks/useFirebaseLogin';

const Label = styled(BaseLabel)`
  .label-main-link {
    align-self: flex-start;
  }
`;

const OrContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0 !important;
`;

const OrText = styled.div`
  font-size: 14rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #252525;
  margin: 30px 18px;
`;

const Line = styled.div`
  height: 0.5px;
  width: 100%;
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray400};
`;

const GoogleText = styled.div`
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 14px;
`;

const GoogleLoginButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #cccccc;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const MAX_LOGIN_ATTEMPTS = 5;

const AdminLogin = () => {
  const { t } = useContext(ConfigContext);
  const { loginAsAdmin } = useAuth();
  const {
    signInWithGoogle,
    isLoading: isLoadingGoogleSignInRequest,
    result: googleSignInRequestResult
  } = useFirebaseLogin();
  const [rememberMeToken] = usePersistedState('remember-me-token', '');

  usePageTitle('signInToUbeya');

  const [numOfAttempts, setNumOfAttempts] = useState(0);

  const onClickLoginWithGoogle = useCallback(
    async (event) => {
      event.preventDefault();
      await signInWithGoogle();
    },
    [signInWithGoogle]
  );

  useEffect(() => {
    const idToken = googleSignInRequestResult?._tokenResponse?.idToken;
    const email = googleSignInRequestResult?._tokenResponse?.email;
    if (idToken) {
      loginAsAdmin({ tokenId: idToken, email, type: 'adminGoogleFirebaseToken' });
    }
  }, [
    googleSignInRequestResult?._tokenResponse?.email,
    googleSignInRequestResult?._tokenResponse?.idToken,
    loginAsAdmin
  ]);

  const handleLoginPress = useCallback(
    async (params) => {
      try {
        await loginAsAdmin({ ...params, rememberMeToken });
      } catch (error) {
        setNumOfAttempts((prev) => prev + 1);
        throw error;
      }
    },
    [loginAsAdmin, rememberMeToken]
  );

  const isUserBlocked = numOfAttempts >= MAX_LOGIN_ATTEMPTS;
  const disableSubmit = isLoadingGoogleSignInRequest || isUserBlocked;

  return (
    <Box onSubmit={handleLoginPress} title={t('signInToUbeya')} inside>
      {({ submitting }) => (
        <>
          <GoogleLoginButton type="button" onClick={onClickLoginWithGoogle} isLoading={isLoadingGoogleSignInRequest}>
            <GoogleIcon />
            <GoogleText> {t('continueWithGoogle')}</GoogleText>
          </GoogleLoginButton>

          <OrContainer>
            <Line />
            <OrText>{t('or').toUpperCase()}</OrText>
            <Line />
          </OrContainer>

          <InputField type="email" name="email" validate={required} title="Email" />

          <InputField
            name="password"
            type="password"
            validate={required}
            title="Password"
            linkTo="/forgot"
            linkText="Forgot password?"
            autocomplete="current-password"
            autocapitalize="none"
          />

          {isUserBlocked && (
            <Label
              error={t('tooManyAttemptesText')}
              linkTo="/forgot"
              linkText={t('tooManyAttemptesResetPasswordLink')}
            />
          )}

          <PrimaryRoundButton disabled={submitting || disableSubmit}>
            {submitting ? <Loader size="xsmall" /> : 'Log In'.toUpperCase()}
          </PrimaryRoundButton>
        </>
      )}
    </Box>
  );
};

export default AdminLogin;
