import React, { useState, useCallback, useContext, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { isTablet } from 'react-device-detect';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import usePortalHook from '../../hooks/usePortal';
import { maxMedia, media, scrollbar } from '../../style';
import { FlexCenter, FlexColumn, FlexSpace, FlexMiddle } from '../Flex';
import { Header2SemiBold } from '../Typography';
import Button from '../Button';
import CircleIcon from '../CircleIcon';
import ConfirmationModal from '../ConfirmationModal';
import PromptClick from '../PromptClick/PromptClick';
import ConfigContext from '../../contexts/ConfigContext';
import SideDetailsModal from './SideDetailsModal';
import useModal from './context/useModal';

const jumpIn = keyframes`
  0%{transform: scale(0.3); opacity: 0.7;}
  90%{transform: scale(1.01); opacity: 1;}
  100%{transform: scale(1);}
`;

const SIZES = {
  fullScreen: css`
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh) * 100);
    animation: ${jumpIn} 300ms;
    ${isTablet && `border-radius: 0;`};
    ${maxMedia.mobile`border-radius: 0;`};
  `,

  full: css`
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh) * 100);
    animation: ${jumpIn} 300ms;

    ${maxMedia.mobile`border-radius: 0`};

    ${media.mobile`
      width: 96vw;
      height: 96vh;
    `}
  `,
  xxlarge: css`
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh) * 100);
    animation: ${jumpIn} 300ms;

    ${maxMedia.mobile`border-radius: 0`};

    ${media.mobile`
    width: 96vw;
    height: 96vh;
    max-width: 1100px;
    max-height: 750px;
  `}
  `,
  xlarge: css`
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh) * 100);
    animation: ${jumpIn} 300ms;

    ${maxMedia.mobile`border-radius: 0`};

    ${media.mobile`
      width: 96vw;
      height: 96vh;
      max-width: 1100px;
      max-height: 700px;
    `}
  `,
  large: css`
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh) * 100);
    animation: ${jumpIn} 300ms;

    ${maxMedia.mobile`border-radius: 0`};

    ${media.mobile`
      width: 90vw;
      height: 80vh;
      max-width: 1100px;
      max-height: 700px;
    `}
  `,
  medium: css`
    width: 90vw;
    max-height: 60vh;

    ${media.mobile`
      max-width: 780px;
    `}
  `,
  small: css`
    width: 90vw;
    max-height: 60vh;

    ${media.mobile`
      max-width: 600px;
    `}
  `,
  smallLong: css`
    width: 90vw;
    max-height: 90vh;

    ${media.mobile`
      max-width: 600px;
    `}
  `,
  smallWide: css`
    width: 90vw;
    max-height: 60vh;

    ${media.mobile`
      max-width: 800px;
    `}
  `,
  xs: css`
    width: 80vw;
    max-height: 60vh;

    ${media.mobile`
      width: 90vw;
      max-width: 400px;
    `}
  `,
  long: css`
    width: 90vw;
    max-height: 90vh;
    height: 90vh;

    ${media.mobile`
      max-width: 650px;
    `}
  `,
  sLong: css`
    width: 90vw;
    max-height: 90vh;
    height: 80vh;

    ${media.mobile`
      max-width: 650px;
    `}
  `
};

const Wrapper = styled(FlexCenter).attrs(() => ({ className: 'modal-wrapper' }))`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(19, 21, 41, 0.7);
  z-index: 5;
`;

const Container = styled(FlexColumn)`
  position: relative;
  box-shadow: 0 6px 20px 0px rgba(47, 60, 79, 0.2);
  background-color: ${({ theme }) => theme.colors.modalBackground};
  border: 1px solid #eee;
  border-radius: 12px;
  ${({ size }) => SIZES[size]}
`;

const TitleRow = styled(FlexSpace)`
  padding: 12px 28px;
  ${({ $showBorder, theme }) =>
    $showBorder &&
    css`
      border-bottom: 1px solid ${theme.colors.modalBorder};
    `}
`;

const TitleContent = styled(Header2SemiBold)`
  flex: 1;
`;

const WarningLine = styled.div`
  color: ${({ theme }) => theme.colors.failure};
  width: 100%;
`;

const Content = styled(FlexColumn).attrs(() => ({ className: 'modal-content' }))`
  flex: 1;
  ${({ $isScrollableContent }) => $isScrollableContent && 'overflow: hidden auto;'}
  ${({ $isPaddingContent }) => $isPaddingContent && 'padding: 32px;'}
  ${scrollbar};
  position: relative;
`;

const Buttons = styled(FlexMiddle)`
  justify-content: flex-end;
  padding: 7px 28px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.modalBorder}`};

  > button:not(:first-child) {
    margin-left: 16px;
  }
`;

const CancelButton = styled(Button).attrs(() => ({ className: 'cancel-button' }))``;
const SubmitButton = styled(Button).attrs(() => ({ className: 'submit-button' }))``;

const Modal = ({
  children,
  onClose,
  title,
  body,
  onConfirm,
  onCancel,
  requiredWarningFooter,
  className,
  usePortal,
  renderButtons,
  formSubmitFailed,
  sideDetails,
  pristine,
  size = 'large',
  confirmText = 'update',
  cancelText = 'cancel',
  promptTitle = 'areYouSure',
  promptBody = 'areYouSureDiscardChanges',
  promptConfirmText = 'discard',
  wrapperClassName = '',
  showConfirmButton = true,
  showCancelButton = false,
  submit = false,
  disabledConfirm = false,
  loadingConfirm = false,
  loadingRenderButton = false,
  closeOnClickOutside = true,
  prompt = false,
  promptOnConfirm = false,
  disableLoadingConfirmButton = false,
  isScrollableContent = true,
  isPaddingContent = true,
  isForm = false,
  showSideDetailsModal = false,
  showCloseButton = true,
  allowEmptyTitle = false,
  permanentWarning,
  formProps,
  ...formModalProps
}) => {
  const { t } = useContext(ConfigContext);

  const [promptContext, setPromptContext] = useState();

  const [isPromptOpen, setIsPromptOpen] = useState(false);

  const handleClose = useCallback(() => {
    if (prompt) {
      setIsPromptOpen(true);
      setPromptContext('close');
    } else {
      onClose();
    }
  }, [onClose, prompt]);

  const { attachModal, detachModal } = useModal();
  useEffect(() => {
    attachModal({ onClose: handleClose, modalOptions: { disableCloseClickOutside: !closeOnClickOutside } });

    return () => {
      detachModal({ onClose: handleClose });
    };
  }, [attachModal, closeOnClickOutside, detachModal, handleClose]);

  const { Portal } = usePortalHook({
    enabled: usePortal,
    closeOnClickOutside: false,
    portalId: 'modal'
  });

  const handleCancel = useCallback(() => {
    if (prompt) {
      setIsPromptOpen(true);
      setPromptContext('cancel');
    } else {
      onCancel ? onCancel() : onClose();
    }
  }, [onCancel, onClose, prompt]);

  const handleConfirmPrompt = useCallback(() => {
    if (promptContext === 'cancel' && onCancel) {
      onCancel();
    } else {
      onClose();
    }
    setIsPromptOpen(false);
  }, [onCancel, onClose, promptContext]);

  return (
    <Portal>
      <Wrapper
        as={isForm ? 'form' : undefined}
        {...formModalProps}
        className={wrapperClassName}
        onClick={() => closeOnClickOutside && handleCancel()}>
        <Container className={className} size={size} onClick={(e) => e.stopPropagation()}>
          {(title || allowEmptyTitle) && (
            <TitleRow className="title" $showBorder={!!title}>
              <TitleContent>{typeof title === 'string' ? t(title) : title}</TitleContent>

              {showCloseButton && <CircleIcon icon={CloseIcon} onClick={handleClose} />}
            </TitleRow>
          )}

          <Content
            className="content"
            id="modal-container"
            $isScrollableContent={isScrollableContent && !showSideDetailsModal}
            $isPaddingContent={isPaddingContent}>
            {t(body) || children}
            {showSideDetailsModal && <SideDetailsModal>{sideDetails}</SideDetailsModal>}
          </Content>

          {(showConfirmButton || showCancelButton || renderButtons) && (
            <Buttons className="buttons">
              {permanentWarning && <WarningLine>{permanentWarning}</WarningLine>}
              {requiredWarningFooter && formSubmitFailed && <WarningLine>{requiredWarningFooter}</WarningLine>}

              {renderButtons?.({
                loadingConfirm: loadingConfirm || loadingRenderButton,
                pristine,
                formProps
              })}

              {showCancelButton && (
                <CancelButton secondaryGray type="reset" disabled={loadingConfirm} onClick={handleCancel}>
                  {t(cancelText)}
                </CancelButton>
              )}

              {/* prompt before submitting */}
              {showConfirmButton && (
                <PromptClick
                  onClick={onConfirm}
                  enable={promptOnConfirm}
                  title={promptTitle}
                  body={promptBody}
                  confirmText={promptConfirmText}
                  showCancelButton>
                  <SubmitButton
                    primary
                    type={submit ? 'submit' : 'button'}
                    disabled={disabledConfirm || (disableLoadingConfirmButton && loadingConfirm)}
                    isLoading={!disableLoadingConfirmButton && loadingConfirm}>
                    {t(confirmText)}
                  </SubmitButton>
                </PromptClick>
              )}
            </Buttons>
          )}
        </Container>
      </Wrapper>

      {/* prompt for closing modal */}
      {isPromptOpen && (
        <ConfirmationModal
          onClose={() => setIsPromptOpen(false)}
          title={t(promptTitle)}
          body={t(promptBody)}
          onConfirm={handleConfirmPrompt}
          confirmText={promptConfirmText}
          showCancelButton
        />
      )}
    </Portal>
  );
};

export default Modal;
