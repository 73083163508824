import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import useInterval from '@ubeya/shared/hooks/useInterval';

const TextWrapper = styled.span`
  text-align: center;
  font-size: 14px;
`;

const RedirectState = ({ text, style = {}, redirect, windowLocation = false, timeout = 5000 }) => {
  const { push } = useHistory();
  useInterval(() => {
    if (windowLocation) {
      window.location = redirect;
    } else {
      push(redirect);
    }
  }, timeout);

  return <TextWrapper style={style}>{text}</TextWrapper>;
};

export default RedirectState;
