import React, { useCallback, useContext } from 'react';
import { Trans } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import createDecorator from 'final-form-calculate';
import { ReactComponent as BaseSuccessfullyChangedPasswordIcon } from '@ubeya/shared-web/assets/successfully-changed-password.svg';
import { ReactComponent as BaseUbeyaIcon } from '@ubeya/shared-web/assets/ubeya_logo_black.svg';
// import { ReactComponent as BaseInvalidLinkIcon } from '@ubeya/shared-web/assets/invalid-link.svg';

import backgroundUbeyaDisplay from '@ubeya/shared-web/assets/blured-ubeya-scheduling.jpg';
import useToaster from '@ubeya/shared/hooks/useToaster';
import {
  Button as BaseButton,
  CheckBoxField as BaseCheckBoxField,
  InputField as BaseInputField,
  Loader,
  SmallBody,
  smallBody
} from '@ubeya/shared-web/components';
import { useQueryParams } from '@ubeya/shared-web/hooks/useQueryParams';
import { required, passwordIndicator, composeValidators, passwordIndicatorData } from '@ubeya/shared/utils/form';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import useAuth from '../hooks/useAuth';
import BaseBox from './Box';
import RedirectState from './RedirectState';

const PASSWORD_ACTIVATION = 'activate';

const Box = styled(BaseBox)`
  position: relative;
  max-width: 500px;
  width: 500px;
  height: 600px;

  padding: 0;

  ${({ $activation }) =>
    $activation &&
    css`
      height: 650px;
    `}

  .box-title {
    ${({ $activation }) =>
      $activation &&
      css`
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 0;
      `}
  }

  .box-sub-title {
    ${({ $activation }) =>
      $activation &&
      css`
        margin-top: 30px !important;
        font-weight: 300;
        font-size: 18px;
        color: ${({ theme }) => theme.colors.gray000};
      `}
  }

  .box-line-text {
    margin-top: 0px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const InputField = styled(BaseInputField)`
  input {
    width: 350px;
  }
`;

const UbeyaIcon = styled(BaseUbeyaIcon)`
  margin-top: 10px;
  width: 90px;
  height: 50px;
`;

const CheckBoxField = styled(BaseCheckBoxField)`
  margin-bottom: 10px !important;
  padding: 5px 0;
  &&& label {
    ${smallBody};
    font-weight: 300;
    font-size: 14px;
    width: 350px;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  bottom: ${({ $activation }) => ($activation ? 10 : 30)}px;
`;

const BodyContainer = styled.div`
  margin-top: ${({ $activation }) => ($activation ? 10 : 30)}px;
  margin-bottom: 0 !important;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PasswordTitle = styled.div`
  font-size: 30px;
  color: #494949;
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-size: 30px;
  color: #494949;
  margin-bottom: 30px;
  font-weight: 500;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
`;

const Button = styled(BaseButton)`
  border-radius: 3px;
  width: 350px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Errors = styled.ul`
  ${smallBody}
  color: ${({ theme }) => theme.colors.failure};
  width: 260px;
  padding: 0 0 15px 0;
  margin-left: 65px;
  align-self: flex-start;

  > li {
    margin-bottom: 3px;
  }
`;

const LoginLink = styled(NavLink).attrs(() => ({ className: 'box-line-text' }))`
  padding-top: ${({ $activation }) => ($activation ? 10 : 20)}px;
  margin-bottom: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  font-size: 14px;
  text-transform: uppercase;
`;

const PasswordIndicator = styled.div`
  color: ${({ theme }) => theme.colors.gray100};
`;

// const InvalidLinkIcon = styled(BaseInvalidLinkIcon)`
//   width: 400px;
//   height: 200px;
// `;

const EmptyWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  height: 100%;
`;

const SuccessfullyChangedPasswordIcon = styled(BaseSuccessfullyChangedPasswordIcon)`
  margin-bottom: 20px;
`;

const SubTitle = styled(SmallBody).attrs(() => ({ className: 'box-sub-title' }))`
  margin: 0 0 10px !important;
  color: ${({ theme }) => theme.colors.gray400};
  text-align: center;
`;

const SubTitleDetails = styled.div`
  margin: 20px;
  font-size: 13rem;
`;

// const SubmitFailedRenderer = () => {
//   const { t } = useContext(ConfigContext);
//   return (
//     <EmptyWrapper>
//       <Title>{t('ohNo')}</Title>

//       <InvalidLinkIcon />
//       <RedirectState text={t('loginInvalidToken')} redirect="/login" style={{ fontSize: '18px', color: '#909298' }} />
//     </EmptyWrapper>
//   );
// };

const SubmitSucceededRenderer = () => {
  const { t } = useContext(ConfigContext);

  return (
    <EmptyWrapper>
      <Title>{t('youDidIt')}</Title>

      <SuccessfullyChangedPasswordIcon />
      {/* <RedirectState
        text={<Trans>loginPasswordChanged</Trans>}
        redirect={redirect || process.env.WEB_URL}
        windowLocation
        style={{ fontSize: '18px', color: '#909298', lineHeight: '25px' }}
        timeout={2000}
      /> */}
    </EmptyWrapper>
  );
};

const decorators = [
  createDecorator({
    field: 'password',
    updates: { passwordIndicatorData }
  })
];

const ChangePassword = () => {
  const { t } = useContext(ConfigContext);
  const { token } = useParams();
  const { type, email } = useQueryParams();
  const { resetPassword } = useAuth();
  const { addResponseError } = useToaster();
  const { loginAsAdmin } = useAuth();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await resetPassword({ ...values, token });
        await loginAsAdmin({ email, password: values.password });
      } catch (err) {
        addResponseError(err);

        throw err;
      }
    },
    [addResponseError, email, loginAsAdmin, resetPassword, token]
  );

  if (!token) {
    return (
      <Box linkTo="/login" linkText={t('loginRedirect')}>
        {() => <RedirectState text={t('loginInvalidToken')} redirect="/login" />}
      </Box>
    );
  }

  return (
    <Wrapper style={{ backgroundImage: `url(${backgroundUbeyaDisplay})` }}>
      <Box $activation={type === PASSWORD_ACTIVATION} onSubmit={handleSubmit} decorators={decorators}>
        {({ valid, submitting, submitFailed, submitSucceeded, values, touched, errors, form }) => {
          if (submitFailed) {
            form.reset();
          }

          if (submitSucceeded) {
            return <SubmitSucceededRenderer />;
          }
          return (
            <>
              {submitSucceeded ? (
                <span>{t('loginLinkToEmail')}</span>
              ) : (
                <>
                  <BodyContainer $activation={type === PASSWORD_ACTIVATION}>
                    <PasswordTitle>
                      {type === PASSWORD_ACTIVATION ? t('welcomeToUbeya') : t('setPassword')}
                    </PasswordTitle>

                    {type === PASSWORD_ACTIVATION && <SubTitle>{t('pleaseChoosePassword')}</SubTitle>}

                    <SubTitleDetails>
                      <div style={{ marginBottom: 10 }}>
                        To enhance security, your new password must meet the following criteria:
                      </div>
                      <li style={{ listStyleType: 'disc' }}>
                        Include at least one character from each of the following categories:
                        <ul
                          style={{
                            listStyleType: 'circle',
                            marginLeft: 10
                          }}>
                          <li>Uppercase letter (A-Z)</li>
                          <li>Lowercase letter (a-z)</li>
                          <li>Digit (0-9)</li>
                          <li>Special character (e.g., !, $, #)</li>
                        </ul>
                      </li>

                      <li style={{ marginTop: 0, listStyleType: 'disc' }}>Be a minimum of 10 characters in length</li>
                    </SubTitleDetails>

                    {email && (
                      <input
                        type="email"
                        name="email"
                        value={email}
                        autoComplete="username"
                        readOnly
                        style={{ display: 'none' }} // Hide the input
                      />
                    )}
                    <InputField
                      name="password"
                      placeholder={t('password')}
                      type="password"
                      validate={composeValidators(required, passwordIndicator)}
                      showErrorMessage={false}
                      autocomplete="new-password"
                      autocapitalize="none"
                    />

                    <Errors>
                      {Object.entries(errors || {})
                        .filter(([key, message]) => touched[key] && message !== 'required')
                        .map(([key, message]) => (
                          <li key={key}>{t(message)}</li>
                        ))}

                      {/* show indicator only if there are no errors */}
                      {values.passwordIndicatorData && (
                        <PasswordIndicator>{t(values.passwordIndicatorData)}</PasswordIndicator>
                      )}
                    </Errors>
                    {type === PASSWORD_ACTIVATION && (
                      <CheckBoxField
                        name="agree"
                        title={
                          <Trans i18nKey="agreeSignup">
                            <Link href="https://www.ubeya.com/privacy/sw-eula/" target="_blank" rel="noreferrer" />
                            <Link
                              href="https://www.ubeya.com/platform-privacy-policy"
                              target="_blank"
                              rel="noreferrer"
                            />
                          </Trans>
                        }
                        validate={(value) => required(value, true)}
                      />
                    )}
                    <Button primary disabled={submitting || !valid}>
                      {submitting ? (
                        <Loader size="xsmall" />
                      ) : type === PASSWORD_ACTIVATION ? (
                        t('setPassword')
                      ) : (
                        t('setPassword')
                      )}
                    </Button>
                    <Header $activation={type === PASSWORD_ACTIVATION}>
                      <UbeyaIcon />
                      <LoginLink to="/login" $activation={type === PASSWORD_ACTIVATION}>
                        {t('loginRedirect')}
                      </LoginLink>
                    </Header>
                  </BodyContainer>
                </>
              )}
            </>
          );
        }}
      </Box>
    </Wrapper>
  );
};

export default ChangePassword;
