import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { changePageTitle } from '@ubeya/shared/hooks/usePageTitle';
import useAccount from '@ubeya/shared/hooks/account/useAccount';
import { FlexColumn, NavigationMenu, FlexCenter, FlexMiddle, Body } from '@ubeya/shared-web/components';
import { ReactComponent as MenuIcon } from '@ubeya/shared-web/assets/expand.svg';
import { ReactComponent as BaseStarIcon } from '@ubeya/shared-web/assets/is-host-star.svg';
import { ReactComponent as ArrowIcon } from '@ubeya/shared-web/assets/minimize.svg';
import { media } from '@ubeya/shared-web/style';
import { thinPrimaryScrollbar } from '@ubeya/shared-web/style/scrollbar';
import { Tooltip } from '../Tooltip';
import ConfigContext from '../../contexts/ConfigContext';

const Wrapper = styled(FlexColumn)`
  position: relative;
  display: none;
  ${media.tablet`display: flex`};
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.backgroundDark};
  transition: 200ms;
  ${({ $isOpen }) => $isOpen && 'width: 250px;'}
`;

const StarIcon = styled(BaseStarIcon).attrs(() => ({ className: 'is-host-star-icon' }))`
  position: absolute;
  top: -2px;
  right: -2px;
`;

const CircleIcon = styled(FlexCenter)`
  padding: 9px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: 200ms;

  ${({ $hideBackground }) => !$hideBackground && 'background: rgb( 99, 93, 245,0.30)'};
`;

const Label = styled(Body)`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.surface};
  transition: 200ms;
  font-weight: medium;
  font-size: 16rem;
  white-space: nowrap;
`;

const Link = styled(FlexMiddle)`
  text-decoration: none;
  padding: 10px;
  transition: 200ms;

  ${({ $userLink, $isOpen }) => !$userLink && !$isOpen && 'justify-content: center;'}

  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      > ${Label} {
        display: none;
      }
    `}

  &.active {
    ${({ $isOpen }) => $isOpen && `background: #dcd9ff38;`}
    > ${CircleIcon} {
      background: #6467ef;
    }
  }

  ${({ $mainIcon }) =>
    $mainIcon &&
    css`
      > ${CircleIcon} {
        padding: 0;
      }
    `}

  &:hover {
    > ${CircleIcon} {
      ${({ $isOpen }) =>
        !$isOpen &&
        css`
          background: ${({ theme }) => theme.colors.primary};
        `};
    }

    ${({ $isOpen }) => $isOpen && 'background: #444677'};
  }
`;

const ToggleButtonWrapper = styled(FlexCenter)`
  position: absolute;
  top: 80px;
  right: -20px;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 100%;
  padding: 2px;
  cursor: pointer;
  z-index: 4;
`;

const ToggleButton = styled(MenuIcon)`
  width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.colors.primary};
  padding: 2px;
  border-radius: 100%;

  ${({ $isOpen, theme }) => ($isOpen ? `> path{fill: ${theme.colors.surface};}` : `stroke: ${theme.colors.surface};`)}
`;

const Links = styled(FlexColumn)`
  ${thinPrimaryScrollbar}

  &:not(:first-child) {
    top: 120px;
  }

  &:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 15px 0;
  }

  &:nth-child(2) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    margin-top: 50px;
    padding-bottom: 15px;
  }
`;

const ItemWrapper = styled.div`
  .is-host-star-icon {
    visibility: hidden;
  }

  &:hover {
    .is-host-star-icon {
      visibility: visible;
    }
  }
`;

const TitleLabel = styled(Body)`
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.7);
  transition: 200ms;
  white-space: nowrap;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const IsHostAppSideBarItem = ({
  to,
  Icon,
  t,
  mainIcon,
  includedFeature,
  hideBackground,
  label,
  isOpen,
  LinkRenderer,
  accountName
}) => {
  const onClick = useCallback(() => changePageTitle(accountName, label, t), [accountName, label, t]);
  return (
    <Tooltip body={t(label)} placement="right" disabled={isOpen}>
      <ItemWrapper>
        <Link
          as={LinkRenderer}
          to={to}
          $isOpen={isOpen}
          onClick={onClick}
          $sequence={label === 'sequences'}
          $mainIcon={mainIcon}>
          <CircleIcon $hideBackground={hideBackground} $sequence={label === 'sequences'}>
            <Icon />
            {!includedFeature && <StarIcon />}
          </CircleIcon>
          <Label>{t(label)}</Label>
        </Link>
      </ItemWrapper>
    </Tooltip>
  );
};

const IsHostAppSideBar = ({ links, linkRenderer: LinkRenderer = NavLink, children, mobileRenderer = children }) => {
  const { t } = useContext(ConfigContext);
  const [isOpen, setIsOpen] = useState(true);
  const {
    account: { name }
  } = useAccount();

  const flatLinks = useMemo(() => links.flat(), [links]);

  return (
    <>
      <NavigationMenu linkRenderer={LinkRenderer} links={flatLinks}>
        {React.cloneElement(mobileRenderer, { isMobile: true })}
      </NavigationMenu>

      <Wrapper $isOpen={isOpen}>
        <ToggleButtonWrapper onClick={() => setIsOpen(!isOpen)}>
          <ToggleButton $isOpen={isOpen} as={isOpen ? ArrowIcon : undefined} />
        </ToggleButtonWrapper>

        <FlexColumn>
          {links.map((subLinks, index) => (
            <Links key={index}>
              {subLinks
                .filter(({ isVisible = true }) => isVisible)
                .map(({ to, icon: Icon, includedFeature, hideBackground, label, onlyText, mainIcon }, linkIndex) =>
                  onlyText ? (
                    isOpen ? (
                      <TitleWrapper key={linkIndex}>
                        <TitleLabel>{t(label)}</TitleLabel>
                      </TitleWrapper>
                    ) : (
                      <></>
                    )
                  ) : (
                    <IsHostAppSideBarItem
                      key={linkIndex}
                      to={to}
                      Icon={Icon}
                      t={t}
                      label={label}
                      isOpen={isOpen}
                      LinkRenderer={LinkRenderer}
                      mainIcon={mainIcon}
                      includedFeature={includedFeature}
                      hideBackground={hideBackground}
                      accountName={name}
                    />
                  )
                )}
            </Links>
          ))}
        </FlexColumn>

        <Link $userLink>{children}</Link>
      </Wrapper>
    </>
  );
};

export default IsHostAppSideBar;
