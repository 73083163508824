import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { ReactComponent as BaseVSign } from '../../assets/v.svg';
import { Tooltip } from '../Tooltip';
import useFormattingMap from '../../hooks/useFormattingMap';
import TimeAgoRenderer from './TimeAgoRenderer';

const VSign = styled(BaseVSign)`
  margin-left: 10px;
  > path {
    fill: ${({ theme }) => theme.colors.success};
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

const CheckedTimeAgoWithTime = ({ rowData, dataKey, className }) => {
  const { parsedLocalTime } = useFormattingMap();
  const value = get(rowData, dataKey);
  const date = parsedLocalTime(value);

  return value ? (
    <Tooltip body={date}>
      <Wrapper>
        <TimeAgoRenderer rowData={rowData} dataKey={dataKey} disabledTooltip />
        <VSign className={className} />
      </Wrapper>
    </Tooltip>
  ) : (
    <></>
  );
};

export default CheckedTimeAgoWithTime;
