import React, { useContext } from 'react';
import { ORDER_STATUSES } from '@ubeya/shared/constants';
import styled from 'styled-components';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import { Select as BaseSelect } from '@ubeya/shared-web/components';

const Select = styled(BaseSelect)`
  min-width: 220px;
`;

export const OrderStatusSelect = ({ value, onChange }) => {
  const { t } = useContext(ConfigContext);

  const options = [
    { value: undefined, label: t('allOrders') },
    ...Object.entries(ORDER_STATUSES).map(([, { label }]) => ({
      value: label,
      label: t(label)
    }))
  ];

  return <Select value={value} options={options} onChange={onChange} usePortal searchable={false} />;
};
