import React, { useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ConfigContextProvider, contextDefaultValues } from '@ubeya/shared-web/contexts/ConfigContext';
import { ErrorBoundary, Toaster } from '@ubeya/shared-web/components';
import {
  Login,
  Logout,
  AutoLogin,
  Unsubscribe,
  ChangePassword,
  CheckVersion,
  ForgotPassword,
  Unauthorized,
  SetupMfa,
  VerifyMfa
} from './';

const AppWrapper = React.memo(({ children }) => {
  return <ErrorBoundary withMessage={false}>{children}</ErrorBoundary>;
});

const App = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const configContext = useMemo(() => ({ ...contextDefaultValues, t, theme }), [t, theme]);

  return (
    <AppWrapper>
      <ConfigContextProvider config={configContext}>
        <Toaster />
        <Switch>
          <Route path="/login/auto/:token" component={AutoLogin} />
          <Route path="/login/:type" component={Login} />
          <Route path="/setupMfa/:type" component={SetupMfa} />
          <Route path="/verifyMfa/:type" component={VerifyMfa} />
          <Route path="/forgot" component={ForgotPassword} />
          <Route path="/changePassword/:token?" component={ChangePassword} />
          <Route path="/unauthorized" component={Unauthorized} />
          <Route path="/unsubscribe" component={Unsubscribe} />
          <Route path="/logout/:type?" component={Logout} />
          <Route path="/ubeyaLogin" component={CheckVersion} />
          <Redirect to="/login/admin" />
        </Switch>
      </ConfigContextProvider>
    </AppWrapper>
  );
};

export default App;
