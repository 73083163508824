import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import { useContext, useMemo } from 'react';
// import { useQuery } from 'react-query';
// import * as api from '../../services/api';
import employmentTypes from './mocks/employmentTypes.json';

const COLORS = ['#1abc9c', '#273c75', '#3498db', '#9b59b6'];

const useEmploymentTypes = () => {
  const { t } = useContext(ConfigContext);
  // const storeKey = ['employmentTypes'];
  // const { isLoading, data } = useQuery(storeKey, api.fetchEmploymentTypes);
  const isLoading = false;
  const data = employmentTypes;

  const employmentTypesOptions = useMemo(
    () => (data?.data || []).map(({ id, slug }) => ({ value: id, label: t(slug) })),
    [data?.data, t]
  );

  const mappedEmploymentTypes = useMemo(
    () =>
      employmentTypesOptions.reduce(
        (prev, { value, label }, index) => ({ ...prev, [value]: { label, color: COLORS[index % COLORS.length] } }),
        {}
      ),
    [employmentTypesOptions]
  );

  return { isLoading, employmentTypesOptions, mappedEmploymentTypes };
};

export default useEmploymentTypes;
