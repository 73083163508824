import React, { memo, useCallback } from 'react';
import HeaderCell from './HeaderCell';
import { HeaderRowWrapper, ColumnsListActions } from './styles';

const HeaderRow = ({
  columns,
  sortBy,
  sortDirection,
  onSort,
  onColumnResize,
  HeaderCellRenderer,
  showColumnsMenu,
  columnsActionListRenderer,
  extraTableActionIconRenderer,
  showExtraTableActionRenderer,
  setColumnStorageName,
  storageName,
  setIsMenuOpen,
  isRtl
}) => {
  const onOpen = useCallback(() => {
    setIsMenuOpen(true);
    setColumnStorageName(storageName);
  }, [setColumnStorageName, setIsMenuOpen, storageName]);

  return (
    <HeaderRowWrapper>
      {columns.map((column) => (
        <HeaderCell
          key={column.dataKey}
          column={column}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSort={onSort}
          onResize={onColumnResize}
          HeaderCellRenderer={HeaderCellRenderer}
          isRtl={isRtl}
        />
      ))}

      {(showColumnsMenu || showExtraTableActionRenderer) && (
        <ColumnsListActions>
          {showColumnsMenu &&
            columnsActionListRenderer?.({
              onOpen
            })}
          {showExtraTableActionRenderer && extraTableActionIconRenderer?.()}
        </ColumnsListActions>
      )}
    </HeaderRowWrapper>
  );
};

export default memo(HeaderRow);
