import React, { useState, useCallback, useMemo, useContext } from 'react';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';
import { TextWithTooltip } from '../../Tooltip';

const FormattedLabel = ({ label }) => {
  return <TextWithTooltip>{label}</TextWithTooltip>;
};

const ProjectLiveViewBranchCostCentersFilter = ({
  slug,
  defaultValue,
  handleClose,
  onApply,
  search,
  onChange,
  externalOptions,
  hideLayout = false
}) => {
  const { t } = useContext(ConfigContext);
  const [values, setValues] = useState(defaultValue || []);

  const costCenterOptions = (externalOptions[slug] || []).map(({ id, value }) => ({
    value: id,
    label: value
  }));

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      search
        ? (costCenterOptions || []).filter(
            ({ label }) =>
              t('branchCostCenters').toLowerCase().includes(search.toLowerCase()) ||
              label.toLowerCase().includes(search.toLowerCase())
          )
        : costCenterOptions,
    [costCenterOptions, search, t]
  );

  if (!filteredOptions.length) {
    return null;
  }

  return (
    <FilterLayout
      name={t('branchCostCenters')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={hideLayout}>
      {filteredOptions.map(({ value, label }) => (
        <DropDown.Item key={value} useTextWithTooltip>
          <CheckBox
            name={`branchCostCenter-${value}`}
            value={values.includes(value)}
            label={<FormattedLabel label={label} />}
            onChange={() => handleChange(value)}
          />
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default ProjectLiveViewBranchCostCentersFilter;
