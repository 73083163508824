import { createCachedSelector } from 're-reselect';
import { useContext } from 'react';
import moment from 'moment';
import { useQuery, useQueryClient } from 'react-query';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import * as api from '../../services/api';
import useOptimisticMutation from '../useOptimisticMutation';
import useCRUD from '../useCRUD';
import { API_DATE_TIME_FORMAT } from '../../constants';
import { mappedArray } from '../../utils/array';
import useToaster from '../useToaster';
import { SORT_BY_STRING } from '../../utils/sorting';
import useAccount from './useAccount';

export const FieldTypes = {
  FIELD_TYPE_TEXT: 1,
  FIELD_TYPE_DATE: 2,
  FIELD_TYPE_OPTIONS: 3,
  FIELD_IMAGE: 4,
  FIELD_DESCRIPTION: 5,
  FIELD_DOCUMENT: 6,
  FIELD_TYPE_BOOLEAN: 7,
  FIELD_TYPE_ADDRESS: 8,
  FIELD_TYPE_DOCUMENT: 9,
  FIELD_TYPE_SIGNATURE: 10
};

const selector = createCachedSelector(
  (data) => data.data,
  (data, t) => t,
  (data, t, dateFormat) => dateFormat,
  (data, t, dateFormat, storeKey) => storeKey,
  (data, t, dateFormat) => {
    const sorter = SORT_BY_STRING('title');

    const fields = (data || [])
      .map((field) =>
        field.isGeneric
          ? {
              ...field,
              name: field.name,
              slug: field.name,
              options: field.options
                ? field.options.map((option) => ({ ...option, title: option.title, slug: option.title })).sort(sorter)
                : undefined
            }
          : { ...field, options: field.options ? field.options.sort(sorter) : undefined }
      )
      .sort(sortFieldsCondition);

    const mappedFields = mappedArray(fields);

    const mappedFieldsValue = fields.reduce((prev, { id, fieldTypeId, isGeneric, options }) => {
      if (!options) {
        switch (fieldTypeId) {
          case FieldTypes.FIELD_TYPE_DATE: {
            return {
              ...prev,
              [id]: (value) =>
                value
                  ? moment(value, API_DATE_TIME_FORMAT).isValid()
                    ? moment(value, API_DATE_TIME_FORMAT).format(dateFormat || 'DD/MM/YYYY')
                    : undefined
                  : value
            };
          }

          case FieldTypes.FIELD_TYPE_DOCUMENT: {
            return {
              ...prev,
              [id]: (value) => (value && Array.isArray(value) ? value[0]?.link : value)
            };
          }

          case FieldTypes.FIELD_TYPE_ADDRESS: {
            return {
              ...prev,
              [id]: (value) => (typeof value === 'string' ? value : value && value.distance)
            };
          }

          default: {
            return {
              ...prev,
              [id]: (value) => value
            };
          }
        }
      }

      const mappedOptions = mappedArray(
        options,
        (option) => option.id,
        ({ title }) => (isGeneric ? t(title) : title)
      );

      return {
        ...prev,
        [id]: (value) =>
          Array.isArray(value) ? value.map((item) => mappedOptions[item]).join(', ') : mappedOptions[value]
      };
    }, {});

    return { fields, mappedFields, mappedFieldsValue };
  }
)({
  keySelector: (data, t, dateFormat, storeKey) => storeKey
});

const sortFieldsCondition = (a, b) => {
  if (a.priority === b.priority) {
    return a.isGeneric && !b.isGeneric ? -1 : !a.isGeneric && b.isGeneric ? 1 : a.id - b.id;
  }
  return a.priority - b.priority;
};

const useFields = () => {
  const { t, dateFormat } = useContext(ConfigContext);
  const { accountId } = useAccount();
  const queryClient = useQueryClient();
  const { addResponseError, addSuccess } = useToaster();

  const storeKey = ['fields', accountId];
  const { isLoading, data } = useQuery(storeKey, async () => api.fetchFields({ accountId }), {
    enabled: !!accountId,
    select: (fieldsData) => selector(fieldsData, t, dateFormat, storeKey.join('#'))
  });

  const { fields = [], mappedFields = {}, mappedFieldsValue = {} } = data || {};

  const { addItem, editItem, deleteItem } = useCRUD(
    storeKey,
    {
      addApi: (field) => api.addField({ accountId, ...field }),
      editApi: (field) => api.updateField({ accountId, fieldId: field.id, ...field }),
      deleteApi: async ({ id }) => {
        try {
          const result = await api.deleteField({ accountId, fieldId: id });
          if (result) {
            addSuccess(t('fieldDeletedSuccessfully'));
          }
          return result;
        } catch (e) {
          addResponseError(e);
        }
      }
    },
    { onSuccess: () => queryClient.invalidateQueries('filters') }
  );

  const { mutateAsync: asyncAttachments } = useOptimisticMutation(
    storeKey,
    (fieldsData) => api.syncFields({ accountId, fields: fieldsData }),
    ({ previousData, deleted, addedWithName }) => ({
      ...previousData,
      data: [...addedWithName, ...previousData.data.filter((field) => !deleted.includes(field.id))]
    })
  );

  const { mutateAsync: sortFields } = useOptimisticMutation(
    storeKey,
    ({ sortedFields }) =>
      api.sortFields({
        accountId,
        fields: (sortedFields || []).map(({ id }, index) => ({ id, priority: index }))
      }),
    ({ previousData, sortedFields }) => ({
      ...previousData,
      data: sortedFields
    }),
    { refetchOnSuccess: true }
  );

  const { mutateAsync: toggleField } = useOptimisticMutation(
    storeKey,
    ({ id, ...value }) => api.updateField({ accountId, fieldId: id, ...value }),
    ({ previousData, id, ...value }) => ({
      ...previousData,
      data: previousData?.data.map((field) => (field.id === id ? { ...field, ...value } : field))
    })
  );

  return {
    isLoading,
    fields,
    mappedFields,
    mappedFieldsValue,
    deleteField: deleteItem,
    addField: addItem,
    editField: editItem,
    asyncAttachments,
    sortFields,
    toggleField
  };
};

export default useFields;
